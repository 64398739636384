import React from "react";
import PageHeader from "../UiComponents/PageHeader";

import CertAction from "../UiComponents/CertAction";
import Milestone from "./Milestone";
const AboutPage = () => {
  return (
    <div>
      <PageHeader
        PageTitle={"About Us"}
        PageDescription={
          "We have rich experience of helping organizations achieve ISO certification and grow their business."
        }
      />
      <section className="text-gray-600 ">
        <div className="max-w-screen-xl mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className=" sm:text-4xl text-3xl mb-4 font-bold text-amber-500">
              Who are we?
            </h1>
            <p className="mb-8 leading-relaxed">
              Globus Certifications Pvt Ltd. (henceforth GCPL) is an independent
              certification body, which provides ISO certification to
              organizations across the globe. Additionally, we also provide
              trainings in ISO, Lean, Six Sigma and other quality methodologies.
              Our mission is be a leading certification body globally. <br/><br/> We, at
              GCPL have more than 16 years of industrial experience as a team
              and can cater to all kinds of industries for their certification
              needs. <br/><br/> We are accredited by UAF (United Accreditation Foundation)
              for ISO 9001:2015, ISO 14001:2015, OHSAS 18001:2007 and ISO
              45001:2018 standards. UAF is a full member of IAF (International
              Accreditation Forum). IAF is the world association of Conformity
              Assessment Accreditation Bodies and other bodies interested in
              conformity assessment in the fields of management systems,
              products, services, personnel and other similar programmes of
              conformity assessment. <br/><br/> Businesses today seek to offer products and
              services that not only meet but also surpass customer
              expectations. At the same time, they are under pressure to cut
              costs to remain competitive. Organisations - both large and small
              require a comprehensive approach to quality improvement that
              resolves the challenges of quality and cost efficiency. <br/><br/> We, at
              Globus Certifications provide unbiased ISO certification services.
              <br/>NOTE: We DO NOT provide consultancy services of any kind.
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center h-full w-full rounded"
              alt="hero"
              src="/images/Who-We-Are.jpeg"
            />
          </div>
        </div>
      </section>

 <Milestone/>
 <CertAction/>
    </div>
  );
};

export default AboutPage;
