import * as Yup from "yup";

export const ContactFormSchema = Yup.object({
    name: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Only alphabets are allowed').required("Name is required"),
    email: Yup.string().email('Invalid Email Address').required("Email is required"),
    contactNo: Yup.string().matches(/^[0-9]{6,10}$/, 'Contact Number is not valid').required("Contact Number is required"),
    message: Yup.string().matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric are allowed').required("Message is required"),
  });

export const CertificationStatusSchema = Yup.object({
    certNumber: Yup.string().matches(/^[a-zA-Z0-9\s\/\-_]*$/, 'Only alphanumeric, slash, hyphens, underscore are allowed').required("Certification Number is required"),
  });