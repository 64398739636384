import React from "react";

const PageHeader = ({PageTitle,PageDescription}) => {
  return (
      <section
        className="bg-cover bg-center  w-full items-center "
        style={{ backgroundImage: `url('/images/banner1.jpg')`}}
      >
        <div className="container mx-auto h-64 flex flex-col gap-2 items-center justify-center text-white">
          <h1 className="text-4xl font-bold">{PageTitle}</h1>
          <p className="text-gray-400 px-6">{PageDescription}</p>
        </div>
      </section>
  );
};

export default PageHeader;
