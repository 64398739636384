import React from "react";
import PageHeader from "../UiComponents/PageHeader";

const OurPolicy = () => {
  return (
    <div>
      <PageHeader
        PageTitle={"Our Policy"}
        PageDescription={
          "Our Quality Policy includes our commitment for meeting customer requirements and to continual improvement."
        }
      />
      <section className="text-gray-600 body-font">
        <div className="max-w-screen-xl px-5 py-16 mx-auto">
          <div className="flex flex-col text-center w-full ">
            <h2 className="text-xs text-indigo-500 tracking-widest font-medium  mb-1">
              OUR POLICY
            </h2>
            <h1 className="sm:text-3xl text-2xl font-semibold mb-4 text-gray-900">
              GLOBUS POLICY STATEMENT
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-4">
              We, at GLOBUS CERTIFICATIONS PRIVATE LIMITED, are committed to
              provide independent and impartial, management systems assessment
              services to its clients that leads to the confidence in
              certification and self to operate globally in QMS, EMS and OHSMS
              technical sectors in accordance with ISO/IEC 17021-1: 2015 and
              other applicable international standards.
            </p>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-4">
              GLOBUS is also committed to enhance value addition through regular
              assessments, and by adapting applicable requirements of
              accreditation board.
            </p>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-4">
              GLOBUS is an entity relying upon the inputs from accreditation
              bodies, clients, data and information obtained in implementation
              of its management system to enable continual improvement with
              relevance to needs and expectations of all its stake holders.
            </p>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              GLOBUS is committed to keep all specific information confidential,
              which is not publicly available.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurPolicy;
