import React, { useState } from 'react'
import PageHeader from '../UiComponents/PageHeader'
import useFetch from "../Utils/useFetch";

const IsoCertServices = () => {
  const { IsFetchLoading: IsServiceListLoading, FetchedData: FetchedServiceList, Refetch: RefetchServiceList } = useFetch('/api/Service/GetServiceList');
 
  const [ServiceDetails, setServiceDetails] = useState({
    id: 0,
    serviceHeading: "",
    serviceSubHeading: "",
    sericeName: "",
    serviceContent: "",
    serviceContent2: "",
    benefitsHeading: "",
    benefitsSubHeading: "",
    benefitsContent: ""
  });

  return (
    <div>
        <PageHeader PageTitle={"Services"} PageDescription={'We have rich experience of helping organizations achieve ISO certification and grow their business.'} />
        <section className="text-gray-600 body-font">
  <div className="max-w-screen-xl px-5 pt-16 pb-10 mx-auto">
    <div className="flex flex-col">
      <div className="h-1 bg-gray-200 rounded overflow-hidden">
        <div className="w-24 h-full bg-yellow-500"></div>
      </div>
      <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-8">
        <h1 className="sm:w-2/5 text-amber-500 font-medium title-font text-2xl mb-2 sm:mb-0">{ServiceDetails?.sericeName || 'Our Certification Services'}</h1>
      </div>
    </div>
    <div className="flex gap-4 ">
      <div className='w-1/4'>
        {FetchedServiceList?.filter((data)=>data.isActive)?.map((service)=>{
          return <div key={service?.id} onClick={()=>{setServiceDetails(service);}} className={`px-2 py-1 hover:bg-amber-500 hover:text-white cursor-pointer mb-2 rounded-lg border-b ${ServiceDetails?.sericeName === service?.sericeName ? 'bg-amber-500 text-white' : 'bg-white'}`}>
             <h6 className=' font-semibold text-lg'>{service?.sericeName}</h6>
            </div>
        })}
      </div>
      <div className='w-full'>
        {ServiceDetails?.id !== 0 ?
        <div className='space-y-4'>
         <h1 className="text-2xl text-amber-500 font-bold ">{ServiceDetails?.serviceHeading}</h1>
         <p>{ServiceDetails?.serviceContent}</p>
         <h1 className="text-2xl text-amber-500 font-bold ">{ServiceDetails?.serviceSubHeading}</h1>
         <p>{ServiceDetails?.serviceContent2}</p>
         <h1 className="text-2xl text-amber-500 font-bold ">{ServiceDetails?.benefitsHeading}</h1>
         <p>{ServiceDetails?.benefitsSubHeading}</p>
         <p>{ServiceDetails?.benefitsContent}</p>
        </div> : 
         <div className='space-y-4'>
          <p>Globus Certifications is the leading certification body worldwide. We are accredited from UAF to sell certificates in QMS, EMS, OHSAS & OH&S standards in more than 30 countries.</p>
          <p>Globus Certifications has tie ups with other certification bodies to sell other ISO standard certifications.</p>
          <h6 className='text-lg font-semibold'>Quality Certifications:</h6>
          <ul className='list-disc space-y-2 ml-6'>
            <li>ISO 9001:2015 (Quality Management System, QMS)</li>
            <li>ISO 14001:2015 (Environmental Management System, EMS)</li>
            <li>OH&S 45001:2018 (Occupational Health and Safety Management Systems, OH&S)</li>
            <li>OHSAS 18001:2007 (Occupational Health and Safety Assessment Series, OHSAS)</li>
            <li>ISO 27001:2013 (Information Security Management System, ISMS)</li>
            <li>ISO 20000 (IT Service Management System Certification, ITSM)</li>
            <li>ISO 22000:2018 (Food Safety Management System, FSMS) and HACCP (Hazard Analysis and Critical Control Points)</li>
            <li>TS 16949, QS 9000, VDA 6.1 (Automotive Quality Management Systems)</li>
            <li>ISO 27034-1:2011 (Information Technology Application Security)</li>
            <li>ISO 12207:2017 (Systems and Software Engineering Software Life Cycle processes)</li>
            <li>ISO 22301:2012 (Business Continuity Management)</li>
            <li>ISO 10002:2018 (Customer Satisfaction)</li>
            <li>ISO 13485:2016 (Medical Devices)</li>
          </ul>
          <p>You may be wondering whether it’s worth the cost and trouble of getting ISO certifications for your business. According to dozens of studies, the answer is a resounding yes! In fact, 98% of companies with ISO certification rated it either a good or very good investment, according to a 2018 survey. A 2018 review of 82 studies on ISO found that certification had “clear benefits” in terms of operations, customer satisfaction and employee engagement.</p>
          <p>Get your company's certification done today.</p>
         </div>
        }
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default IsoCertServices