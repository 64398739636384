import React from "react";
import PageHeader from "../UiComponents/PageHeader";

const OurAccreditation = () => {
  return (
    <div>
      <PageHeader
        PageTitle={"Our Accreditation"}
        PageDescription={"Our Accreditation"}
      />
      <section className="text-gray-600 ">
        <div className="max-w-screen-xl px-5 py-16 mx-auto">
          {/* <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-bold text-amber-500 mb-4 ">
            Our Accreditation
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              In the business world, there are many practices that focus on
              cutting costs and eliminating waste at each stage. Lean and Six
              Sigma are two of the most popular methodologies in achieving these
              results. Both of them take different approaches and yet focus on
              achieving the same goal. Many organisations combine both the
              methodologies, simply labeled as Lean Six Sigma, to increase their
              efficiency and get better results while others choose one
              methodology over the other.
            </p>
          </div> */}

          <div className="flex flex-col text-center w-full mb-10">
            <h1 className="sm:text-3xl text-2xl font-medium  mb-4 text-amber-500">
            Our Accreditation
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            Globus has always embraced an underlying commitment to deliver excellence in everything we do. Whether that is in the projects we deliver, the development of our staff or working towards a greener future, we ensure that our company values are at the heart of everything we do. As a company, we continue to strive for excellence and this has been recognised with the top accreditations/arrangements we have for following standards:
            </p>
          </div>

          <div className="flex flex-wrap mb-14">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 9001
              </h2>
              {/* <p className="leading-relaxed text-base mb-4">
                This is considered to be the beginner’s course in Six Sigma
                since a professional learn the foundational elements of the Six
                Sigma Methodology. After successfully completing this training
                course, the professional can lead to limited improvement
                projects or join a team of Certified Green and Black Belt
                individuals for complex improvement projects.
              </p> */}
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 14001
              </h2>
              {/* <p className="leading-relaxed text-base mb-4">
                This training course deals with the advanced elements of Six
                Sigma Methodology. The professional then understand how to
                properly implement, perform, interpret and apply Six Sigma
                principles and can lead complex improvement projects under a
                Certified Black Belt.
              </p> */}
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 45001
              </h2>
              {/* <p className="leading-relaxed text-base mb-4">
                This is the expert level course that gives professionals an
                insight into the Six Sigma Methodology and equips them with the
                knowledge to lead complex improvement projects. A professional
                can implement, perform, interpret and apply Six Sigma principles
                proficiently.
              </p> */}
            </div>
          </div>

          <div className="flex flex-wrap mb-14">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 22000
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 27001
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 27701
              </h2>
            </div>
          </div>

          <div className="flex flex-wrap mb-14">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 20000-1
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 21001
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 22301
              </h2>
            </div>
          </div>

          <div className="flex flex-wrap mb-14">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 13485
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 41001
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 42001
              </h2>
            </div>
          </div>

          <div className="flex flex-wrap mb-14">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 55001
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 7101
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 37001
              </h2>
            </div>
          </div>

          <div className="flex flex-wrap mb-14">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              ISO 50001
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              SOC2
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              CMMI
              </h2>
            </div>
          </div>

          <div className="flex flex-wrap mb-14">
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              PCI-DSS
              </h2>
            </div>
            <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              VAPT
              </h2>
            </div>
            {/* <div className="xl:w-1/3 lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-amber-200 border-opacity-60">
              <h2 className="text-lg sm:text-xl text-gray-900 font-medium  mb-2">
              CMMI
              </h2>
            </div> */}
          </div>

     

         
          {/* <div className="flex flex-col text-center w-full mb-20">
            <h1 className="sm:text-3xl text-2xl font-medium  mb-4 text-amber-500">
              Why Globus Certifications
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
              Global Certifications offers Lean and Six Sigma
              Certificationtrainingcoursesin India. We conduct instructor-led
              classroom training courses where our experts help you get an
              in-depth knowledge of the concepts and applications of Lean and
              Six Sigma methodology. Get in touch with us at
              info@gcert.com for details about our Lean and Six
              Sigma Certification Training in India.
            </p>
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default OurAccreditation;
