import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const DropdownMenu = ({ buttonLabel, submenuItems,mainMenuPath }) => {
  const { origin } = window.location;

    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const handleMouseEnter = () => {
      setSubMenuOpen(true);
    };
  
    const handleMouseLeave = () => {
      setSubMenuOpen(false);
    };
  
    const handleMenuItemClick = (action,labelPath) => {
      if (action === 'link') {
        navigate(`${labelPath}`)
      } else if (action === 'pdf') {
        window.open(`${origin}${labelPath}`, '_blank');
      }
    };
  
    return (
      <div className="relative inline-block">
        <button
          className={`font-semibold hover:text-amber-500 ${mainMenuPath === location.pathname ? "text-amber-500" : "text-gray-800"} `}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={()=>{
            if(mainMenuPath !== "/undefined"){
              navigate(`${mainMenuPath}`)
            }
          }}
        >
          {buttonLabel}
        </button>
        
        {isSubMenuOpen && (
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="absolute z-10  bg-white border border-gray-300 rounded-lg shadow-lg">
            <ul>
              {submenuItems.map((item, index) => (
                <li
                  key={index}
                  className="py-2 px-4 font-medium hover:bg-gray-100 hover:text-amber-500 whitespace-nowrap cursor-pointer"
                  onClick={() => handleMenuItemClick(item.action,item.labelPath)}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  };
export default DropdownMenu