import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, Switch, DrawerContent, DrawerCloseButton, Button, useDisclosure, Input, Textarea, Center, Spinner, FormControl, FormLabel } from "@chakra-ui/react";
import React, { useState, useMemo, useCallback } from "react";
import usePost from "../Utils/usePost";
import useFetch from "../Utils/useFetch";
import { FiEdit, FiPlus } from 'react-icons/fi';
import { AgGridReact } from "ag-grid-react";

const AddServices = () => {

  const { IsPostLoading: IsAddServiceLoading, PostData: PostAddServiceData } = usePost('/api/Service/SaveService');
  const { IsFetchLoading: IsServiceListLoading, FetchedData: FetchedServiceList, Refetch: RefetchServiceList } = useFetch('/api/Service/GetServiceList');
  const {
    isOpen: isAddServiceOpen,
    onOpen: onAddServiceOpen,
    onClose: onAddServiceClose,
  } = useDisclosure();
  const [ServiceDetails, setServiceDetails] = useState({
    id: 0,
    serviceHeading: "",
    serviceSubHeading: "",
    sericeName: "",
    serviceContent: "",
    serviceContent2: "",
    benefitsHeading: "",
    benefitsSubHeading: "",
    benefitsContent: "",
    isActive: false,
    featuredServiceHeading:'',
    featuredServiceContent:'',
    isFeatured: false
  });

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Service Name",
      field: "sericeName",
    },
    {
      headerName: "Service Heading",
      field: "serviceHeading",
    },
    {
      headerName: "Service Sub Heading",
      field: "serviceSubHeading",
    },
    {
      headerName: "Service Content",
      field: "serviceContent",
    },
    {
      headerName: "Service Content 2",
      field: "serviceContent2",
    },
    {
      headerName: "Benefits Heading",
      field: "benefitsHeading",
    },
    {
      headerName: "Benefits Sub Heading",
      field: "benefitsSubHeading",
    },
    {
      headerName: "Benefits Content",
      field: "benefitsContent",
    },
    {
      headerName: "Active",
      field: "isActive",
      cellRenderer:({data}) => <Switch 
        isChecked={data.isActive} 
        onChange={async({target})=>{
         let body = {
           ...data,
           isActive: target.checked
         };
         console.log("Switch body",body);
         const response = await PostAddServiceData(body);
         if(response) {
           RefetchServiceList();
         }
        }}
        colorScheme="green" 
       />
    },
    {
      headerName: "Featured Heading",
      field: "featuredServiceHeading",
    },
    {
      headerName: "Featured Content",
      field: "featuredServiceContent",
    },
    {
      headerName: "Featured",
      field: "isFeatured",
      cellRenderer:({data}) => data.featuredServiceHeading && <Switch 
       isChecked={data.isFeatured} 
       onChange={async({target})=>{
        let body = {
          ...data,
          isFeatured: target.checked
        };
        console.log("Switch body",body);
        const response = await PostAddServiceData(body);

        if(response) {
          RefetchServiceList();
        }
       }}
       colorScheme="green" 
       />
    },
    {
      headerName: "Action",
      pinned: 'right',
      cellRenderer: ({data}) => <Button onClick={()=>{
        setServiceDetails(data);
        onAddServiceOpen();
      }} size={'xs'} leftIcon={<FiEdit size={'14px'}/>} colorScheme="blue" >Edit</Button>
    },
  ]);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass: "no-border",
    resizable: true
  }));

  const SaveServiceData = async () => {
    // e.preventDefault();
     const response = await PostAddServiceData({
      ...ServiceDetails,
      isActive: true,
      isFeatured: true
     });
     if(response){
      RefetchServiceList();
      setServiceDetails({
        id: 0,
        serviceHeading: "",
        serviceSubHeading: "",
        sericeName: "",
        serviceContent: "",
        serviceContent2: "",
        benefitsHeading: "",
        benefitsSubHeading: "",
        benefitsContent: "",
        isActive: false,
        featuredServiceHeading:'',
        featuredServiceContent:'',
        isFeatured: false
      });
      onAddServiceClose();
     }
  }
  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Services</h1>
        <div>
          <Button
            size={"sm"}
            onClick={() => {
              onAddServiceOpen();
            }}
            leftIcon={<FiPlus size={"16px"} />}
            colorScheme="orange"
          >
            Add Service
          </Button>
        </div>
      </div>

      {IsServiceListLoading ? <Center>
  <Spinner thickness='4px' color={'orange.500 '}  size='lg'  />
</Center> : <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={FetchedServiceList} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
          rowSelection="single"
        />
      </div>}
      
      <Drawer
        isOpen={isAddServiceOpen}
        placement="right"
        size={"md"}
        onClose={() => {
          setServiceDetails({
            id: 0,
            serviceHeading: "",
            serviceSubHeading: "",
            sericeName: "",
            serviceContent: "",
            serviceContent2: "",
            benefitsHeading: "",
            benefitsSubHeading: "",
            benefitsContent: ""
          });
          onAddServiceClose();
        }}
      >
        <DrawerOverlay />
        <DrawerContent roundedLeft={"3xl"}>
          <DrawerCloseButton mt={1} />
          <DrawerHeader
            textColor={"gray.800"}
            fontSize={"lg"}
            backgroundColor={"orange.300"}
            roundedTopLeft={"3xl"}
            roundedBottomLeft={"xl"}
            fontWeight={"bold"}
          >
            Add Service
          </DrawerHeader>
          <DrawerBody className="space-y-6 mt-10">
            <FormControl variant='floating'>
              <Input value={ServiceDetails?.sericeName || ''} onChange={({target})=>{setServiceDetails({...ServiceDetails,sericeName : target.value})}} borderColor={'gray.400'} placeholder="Service Name" />
              <FormLabel>Service Name</FormLabel>
            </FormControl>
            <FormControl variant={'floating'}>
              <Input  value={ServiceDetails?.serviceHeading || ''} onChange={({target})=>{setServiceDetails({...ServiceDetails,serviceHeading : target.value})}}  borderColor={'gray.400'} placeholder="Service Heading" />
              <FormLabel>Service Heading</FormLabel>
            </FormControl>
            <FormControl variant={'floating'}>
              <Textarea value={ServiceDetails?.serviceContent || ''} onChange={({target})=>{setServiceDetails({...ServiceDetails,serviceContent : target.value})}} borderColor={'gray.400'} placeholder="Service Content" />
              <FormLabel>Service Content</FormLabel>
            </FormControl>
            <FormControl variant={'floating'}>
              <Input value={ServiceDetails?.serviceSubHeading || ''} onChange={({target})=>{setServiceDetails({...ServiceDetails,serviceSubHeading : target.value})}} borderColor={'gray.400'} placeholder="Service Sub Heading" />
              <FormLabel>Service Sub Heading</FormLabel>
            </FormControl>
            <FormControl variant={'floating'}>
              <Textarea value={ServiceDetails?.serviceContent2 || ''} onChange={({target})=>{setServiceDetails({...ServiceDetails,serviceContent2 : target.value})}} borderColor={'gray.400'} placeholder="Service Content 2" />
              <FormLabel>Service Content 2</FormLabel>
            </FormControl>
            <FormControl variant="floating">
              <Input value={ServiceDetails?.benefitsHeading || ''} onChange={({target})=>{setServiceDetails({...ServiceDetails,benefitsHeading : target.value})}} borderColor={'gray.400'} placeholder="Benefits Heading" />
              <FormLabel>Benefits Heading</FormLabel>
            </FormControl>
            <FormControl variant={'floating'}>
              <Input value={ServiceDetails?.benefitsSubHeading || ''} onChange={({target})=>{setServiceDetails({...ServiceDetails,benefitsSubHeading : target.value})}} borderColor={'gray.400'} placeholder="Benefits Sub Heading" />
              <FormLabel>Benefits Sub Heading</FormLabel>
            </FormControl>
            <FormControl variant={'floating'}>
              <Textarea rows={4} value={ServiceDetails?.benefitsContent || ''} onChange={({target})=>{setServiceDetails({...ServiceDetails,benefitsContent : target.value})}} borderColor={'gray.400'} placeholder="Benefits Content" />
              <FormLabel>Benefits Content</FormLabel>
            </FormControl>
            <FormControl variant={'floating'}>
              <Input value={ServiceDetails?.featuredServiceHeading || ''} onChange={({target})=>{setServiceDetails({...ServiceDetails,featuredServiceHeading : target.value})}} borderColor={'gray.400'} placeholder="Featured Heading" />
              <FormLabel>Featured Heading</FormLabel>
            </FormControl>
            <FormControl variant={'floating'}>
              <Textarea rows={4} value={ServiceDetails?.featuredServiceContent || ''} onChange={({target})=>{setServiceDetails({...ServiceDetails,featuredServiceContent : target.value})}} borderColor={'gray.400'} placeholder="Featured Content" />
              <FormLabel>Featured Content</FormLabel>
            </FormControl>
          </DrawerBody>
          <DrawerFooter>
            <Button
              colorScheme="orange"
              variant="outline"
              mr={3}
              onClick={() => {
                setServiceDetails({
                  id: 0,
                  serviceHeading: "",
                  serviceSubHeading: "",
                  sericeName: "",
                  serviceContent: "",
                  serviceContent2: "",
                  benefitsHeading: "",
                  benefitsSubHeading: "",
                  benefitsContent: ""
                });
                onAddServiceClose();
              }}
            >
              Cancel
            </Button>
            <Button isLoading={IsAddServiceLoading} loadingText="Saving..." onClick={()=>{
              SaveServiceData();
            }} colorScheme="orange">Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  )
}

export default AddServices