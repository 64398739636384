import { Button, Checkbox, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi';
import usePost from '../Utils/usePost';
import { useAuth } from '../Utils/AuthContext';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {

    const { userLogin } = useAuth();
    const navigate = useNavigate();
    const { IsPostLoading: IsLoginLoading, PostData: PostLoginData} = usePost('/api/Account/UserLogin');
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const [LoginDetails, setLoginDetails] = useState({
      userName: '',
      loginPassword: '',
      generatedToken: '',
      isRemember : false
    });

    const HandleSignIn = async (e) => {
      e.preventDefault();

      console.log("Login body",LoginDetails);
      const response = await PostLoginData(LoginDetails);
      // console.log("response",response);
      if(response !== "Invalid login details."){
        await userLogin(response,true);
        navigate('/admin/addcertificate');
      }else{
        alert(response);
      }
    }
  return (
    <section className="bg-gradient-to-tr from-indigo-200 via-red-200 to-yellow-100 ">
    <div className="w-full h-full flex  justify-center items-center ">
   <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
     <div className="w-full bg-white rounded-2xl shadow  md:mt-0 sm:max-w-lg xl:p-0 ">
     <div className="p-4 space-y-4 md:space-y-6 sm:p-8">
           <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-2xl ">
             Sign in to your account
           </h1>
           <form className="space-y-4 " onSubmit={HandleSignIn} >
             <div className="space-y-2">
               <label>Username</label>
               <Input 
                 value={LoginDetails?.userName || ''}
                 onChange={({target})=>{
                  setLoginDetails({...LoginDetails,userName: target.value})
                 }}
                 isRequired
                 type="text"
                 borderColor={"gray.400"}
                 placeholder={"Username"}
               />
             </div>
             <div className="space-y-2">
               <label>Password</label>
               <InputGroup size="md">
                 <Input
                   value={LoginDetails?.loginPassword || ''}
                   onChange={({target})=>{
                    setLoginDetails({...LoginDetails,loginPassword: target.value})
                   }}
                   isRequired
                   type={show ? "text" : "password"}
                   borderColor={"gray.400"}
                   placeholder={"Password"}
                 />
                  <InputRightElement width="2.5rem">
                    <Button h="1.75rem" size="xs" onClick={handleClick}>
                      {show ? <FiEyeOff size='14px'/> : <FiEye size='14px'/> }
                    </Button>
                  </InputRightElement>
                </InputGroup>
             </div>
             {/* <div className="flex items-center justify-between">
               <Checkbox isChecked={LoginDetails?.isRemember} onChange={({target})=>{
                setLoginDetails({...LoginDetails,isRemember: target.checked })
               }} size={"md"}>
                 <span className="text-sm">Remember Me</span>
               </Checkbox>
               <Button
                 size={"sm"}
                 variant={"link"}
                 colorScheme={"messenger"}
               >
                 Forgot Password?
               </Button>
             </div> */}
             <Button isLoading={IsLoginLoading} loadingText="Signing In..." type="submit" w={"full"} colorScheme="orange">
               Sign In
             </Button>
           </form>
         </div>
     </div>
   </div>
   </div>
 </section>
  )
}

export default AdminLogin