import React from 'react'
import PageHeader from '../UiComponents/PageHeader'
import { MdOutlineRequestQuote } from 'react-icons/md'
import { Avatar } from '@chakra-ui/react';
import { FiFileText } from "react-icons/fi";
import { GoFileBinary } from "react-icons/go";
import { LuFileBadge,LuFileCheck  } from "react-icons/lu";
import { AiOutlineAudit } from "react-icons/ai";

const IsoCertificationProcess = () => {

    const HowToGetCertData = [
        { 
          stepNumber: 1,
          stepIcon:  <FiFileText color="orange" size="3rem"/>,
          stepTitle : 'Application Process',
          stepDescription: 'Prospective Clients may contact GCPL through mediums like e-mail, letter, phone, etc to inquire about certifications. Once the initial understanding is shared with prospective Client, they are required to share formal application (in GCPL format) with details about their company and other specific requirements related to certification sought.'
        },
        {
          stepNumber: 2,
          stepIcon:  <MdOutlineRequestQuote color="orange" size="3rem"/>,
          stepTitle : 'Proposal and Contract',
          stepDescription: 'Based on requirements of Client, a proposal shall be prepared with details of services to be provided by GCPL, timelines, commercial fees, terms & conditions, etc. Based on the proposal, contract will be prepared to be signed by both parties (Client and GCPL).'
        },
        {
          stepNumber: 3.1,
          stepIcon:  <GoFileBinary color="orange" size="3rem"/>,
          stepTitle : 'Mobilization of Audit Team',
          stepDescription: "GCPL shall mobilize the Audit team based on project requirements. The relevant competencies will be evaluated. The audit team will be briefed about the requirements and the expectations of the project. The Client will be communicated the details of the Audit team. If there is any suggestion regarding the composition of the team from the Client, GCPL shall analyze and take appropriate action."
        },
        {
          stepNumber: 3.2,
          stepIcon:  <GoFileBinary color="orange" size="3rem"/>,
          stepTitle : 'Stage 1 Audit',
          stepDescription: "GCPL shall plan Initial Certification Audit (Stage-1) audit activity on completion of contract review and acceptance for certification agreements. Stage 1 audit is conducted before the Certification audit to provide a macro level assessment of the status of implementation and identification of any major deficiencies in the compliance of the documented quality system with the requirements of the certification standards, for corrective actions to be taken in advance of the certification audit. It provides valuable inputs to give confidence to the clients and saves time for taking necessary corrective action, later."
        },
        {
          stepNumber: 4,
          stepIcon:  <LuFileCheck color="orange" size="3rem"/>,
          stepTitle : 'Stage 2 Audit',
          stepDescription: "Stage 2 audit is intended to ensure that the management system effectively conforms to the requirements of the applicable standard. All relevant locations as part of scope of work will be monitored and assessed. The Client is apprised of the Audit findings and next steps for certification process. The non-conformances need to be addressed and closed by Client within a stipulated period of time. Stage 2 Audit shall take place at the site(s) of Client."
        },
        {
          stepNumber: 5,
          stepIcon:  <LuFileBadge color="orange" size="3rem"/>,
          stepTitle : 'Certification',
          stepDescription: 'Based on the Stage 1 and 2 Audit, the Audit team shall recommend whether or not to grant certification, along with the terms, conditions or observations. The GCPL certification committee shall analyze all information and audit evidence shared by the Audit team and documents gathered during Stage 1 and 2 Audit to agree on the audit conclusions. The certification decision shall be communicated to the Client.'
        },
        {
          stepNumber: 6.1,
          stepIcon:  <AiOutlineAudit color="orange" size="3rem"/>,
          stepTitle : 'Surveillance Audits',
          stepDescription: 'The surveillance audits need to be conducted at least once a year to ensure adherence to ISO standard and continual improvement steps taken by the organization. Any changes to management system which might have taken place since last audit should meet the requirement of the standard and should have been implemented effectively. Surveillance audits are not necessarily full system audits and are conducted on Client premises.'
        },
        {
          stepNumber: 6.2,
          stepIcon:  <AiOutlineAudit color="orange" size="3rem"/>,
          stepTitle : 'Renewal/Recertification',
          stepDescription: 'The renewal/recertification includes a re-evaluation of the organization’s documented Quality Management System (QMS). The recertification audit will be conducted before the expiry of the existing certification. Prior notice shall be given to Client regarding the Audit. The Client needs to communicate if there are changes like addition of new processes/services, regulatory requirement or new product/services addition or change of location, etc. The recertification audits will evaluate the adherence to all of the requirements of the relevant management system standard.'
        },
      ]

  return (
    <div>
        <PageHeader PageTitle={'Certification Process'} PageDescription={'We have rich experience of helping organizations to achieve results for ISO certification.'} />
        <section className="text-gray-600 ">
  <div className="max-w-screen-xl px-5 py-16 mx-auto flex flex-wrap">
    {HowToGetCertData?.map((data,i)=>{
       return <div key={i} className="flex relative pt-10 pb-16 sm:items-center md:w-2/3 mx-auto">
       <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
         <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
       </div>
       <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-500 text-white relative z-10  font-medium text-sm">{data?.stepNumber}</div>
       <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
         <Avatar size={'xl'} bgColor={'yellow.100'} icon={data?.stepIcon} />
         <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
           <h2 className="font-medium  text-gray-900 mb-1 text-xl">{data?.stepTitle}</h2>
           <p className="leading-relaxed">{data?.stepDescription}</p>
         </div>
       </div>
     </div>
    })}
  </div>
</section>
    </div>
  )
}

export default IsoCertificationProcess