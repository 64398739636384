import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Button, useDisclosure, Input, Textarea, Select, Center, Spinner } from "@chakra-ui/react";
import React, { useState, useMemo, useCallback } from "react";
import { FiPlus } from "react-icons/fi";
import DatePicker from "react-datepicker";
import moment from "moment";
import usePost from "../Utils/usePost";
import { AgGridReact } from "ag-grid-react";
import useFetch from "../Utils/useFetch";
import { FiEdit } from "react-icons/fi";

const AddFraudulantActivityNotice = () => {

  const { IsPostLoading: IsAddFraudulantActivityNoticeLoading, PostData: PostAddFraudulantActivityNoticeData } = usePost('/api/FraudulantActivityNotice/SaveFraudulantActivityNotice');
  const { IsFetchLoading: IsAddFraudulantActivityNoticeListLoading, FetchedData: FetcheFraudulantActivityNoticeList, Refetch: RefetchFraudulantActivityNoticeList } = useFetch('/api/FraudulantActivityNotice/GetFraudulantActivityNoticeList');

  const {
    isOpen: isAddFraudulantActivityNoticeOpen,
    onOpen: onAddFraudulantActivityNoticeOpen,
    onClose: onAddFraudulantActivityNoticeClose
  } = useDisclosure();
  const [FraudulantActivityNoticeDetails, setFraudulantActivityNoticeDetails] = useState({
    issueDate: "",
    certificationTraining: "",
    companyName: "",
    emailIdPhone: "",
    fraudulentActivity: ""
  });

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [ columnDefs ] = useState([
    {
      headerName: "Date",
      field: "issueDate",
    },
    {
      headerName: "Certificate/Training",
      field: "certificationTraining",
    },
    {
      headerName: "CompanyName",
      field: "companyName",
    },
    {
      headerName: "Email ID/ Contact No",
      field: "EmailIdPhone",
    },
    {
      headerName: "Fraudulent Activity",
      field: "fraudulentActivity",
    },
   
    {
      headerName: "Action",
      pinned: 'right',
      cellRenderer: ({data}) => <Button onClick={()=>{
        setFraudulantActivityNoticeDetails({
          ...data,
          issueDate: new Date(data.issueDate)
        });
        onAddFraudulantActivityNoticeOpen();
      }} size={'xs'} leftIcon={<FiEdit size={'14px'}/>} colorScheme="blue" >Edit</Button>
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass: "no-border",
    enableCellTextSelection: true,
    resizable: true
  }));


  const HandleFraudulantActivityNoticeForm = async () => {
    // e.preventDefault();

    let body = {
       ...FraudulantActivityNoticeDetails,
       issueDate: moment(FraudulantActivityNoticeDetails?.issueDate).format('YYYY-MM-DD')
    }
    const response = await PostAddFraudulantActivityNoticeData(body);
    if (response) {
        RefetchFraudulantActivityNoticeList();
      setFraudulantActivityNoticeDetails({
        issueDate: "",
        certificationTraining: "",
        companyName: "",
        emailIdPhone: "",
        fraudulentActivity: "",
      });
      onAddFraudulantActivityNoticeClose();
    }
  }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Fraudulant Activity Notice</h1>
        <div>
          <Button
            size={"sm"}
            onClick={() => {
              onAddFraudulantActivityNoticeOpen();
            }}
            leftIcon={<FiPlus size={"16px"} />}
            colorScheme="orange"
          >
            Add Fraudulant Activity Notice
          </Button>
        </div>
      </div>
      {IsAddFraudulantActivityNoticeListLoading ? <Center>
        <Spinner thickness='4px' color={'orange.500 '}  size='lg'  />
      </Center> : <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={FetcheFraudulantActivityNoticeList} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
           paginationPageSize={20}
           paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>}

      <Drawer
        isOpen={isAddFraudulantActivityNoticeOpen}
        placement="right"
        size={"md"}
        onClose={() => {
          setFraudulantActivityNoticeDetails({
            issueDate: "",
        certificationTraining: "",
        companyName: "",
        emailIdPhone: "",
        fraudulentActivity: "",
          });
          onAddFraudulantActivityNoticeClose();
        }}
      >
        <DrawerOverlay />
        <DrawerContent roundedLeft={"3xl"}>
          <DrawerCloseButton mt={1} />
          <DrawerHeader
            textColor={"gray.800"}
            fontSize="lg"
            backgroundColor={"orange.300"}
            roundedTopLeft={"3xl"}
            roundedBottomLeft={"xl"}
            fontWeight="bold"
          >
            Add Fraudulant Activity Notice
          </DrawerHeader>
          {/* <form onSubmit={HandleCertificateForm}> */}
          <DrawerBody className="space-y-4">
          <div className="space-y-2">
              <label>Issue Date</label>
              <div>
                <DatePicker
                  required
                  className="bg-white border !w-[29rem] border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block  p-2.5   outline-none"
                  selected={FraudulantActivityNoticeDetails?.issueDate || ""}
                  onChange={(date) => {
                    setFraudulantActivityNoticeDetails({
                      ...FraudulantActivityNoticeDetails,
                      issueDate: date,
                    });
                  }}
                  placeholderText="Issue Date"
                  dateFormat={"dd-MM-yyyy"}
                />
              </div>
            </div>
            <div className="space-y-2">
              <label>Certificate Training</label>
              <Input
                isRequired
                value={FraudulantActivityNoticeDetails?.certificationTraining || ""}
                onChange={({ target }) => {
                  setFraudulantActivityNoticeDetails({
                    ...FraudulantActivityNoticeDetails,
                    certificationTraining: target.value,
                  });
                }}
                borderColor={"gray.400"}
                placeholder="Certificate Training"
              />
            </div>
            <div className="space-y-2">
              <label>Company Name</label>
              <Input
                isRequired
                value={FraudulantActivityNoticeDetails?.companyName || ""}
                onChange={({ target }) => {
                  setFraudulantActivityNoticeDetails({
                    ...FraudulantActivityNoticeDetails,
                    companyName: target.value,
                  });
                }}
                borderColor={"gray.400"}
                placeholder="Company Name"
              />
            </div>
            <div className="space-y-2">
              <label>EmailId/Phone number</label>
              <Textarea
                isRequired
                rows={4}
                value={FraudulantActivityNoticeDetails?.emailIdPhone || ""}
                onChange={({ target }) => {
                  setFraudulantActivityNoticeDetails({
                    ...FraudulantActivityNoticeDetails,
                    emailIdPhone: target.value,
                  });
                }}
                borderColor={"gray.400"}
                placeholder="Company Address"
              />
            </div>
            <div className="space-y-2">
              <label>Fraudulent Activity</label>
              <Textarea
                isRequired
                rows={4}
                value={FraudulantActivityNoticeDetails?.fraudulentActivity || ""}
                onChange={({ target }) => {
                  setFraudulantActivityNoticeDetails({
                    ...FraudulantActivityNoticeDetails,
                    fraudulentActivity: target.value,
                  });
                }}
                borderColor={"gray.400"}
                placeholder="Scope of the Certification"
              />
            </div>
           
          </DrawerBody>
          <DrawerFooter>
            <Button
              colorScheme="orange"
              variant="outline"
              mr={3}
              onClick={() => {
                setFraudulantActivityNoticeDetails({
                  status: "",
                  certificate: "",
                  issueDate: "",
                  expiryDate: "",
                  description: "",
                  standard: "",
                  organization: "",
                  address: "",
                });
                onAddFraudulantActivityNoticeClose();
              }}
            >
              Cancel
            </Button>
            <Button isLoading={IsAddFraudulantActivityNoticeLoading} loadingText="Saving..." onClick={()=>{
              HandleFraudulantActivityNoticeForm();
            }} colorScheme="orange">Save</Button>
          </DrawerFooter>
          {/* </form> */}
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default AddFraudulantActivityNotice;
