import React from 'react'
import PageHeader from '../UiComponents/PageHeader'
import Milestone from './Milestone'
import CertAction from '../UiComponents/CertAction'

const IsoTraining = () => {
  return (
    <div>
        <PageHeader PageTitle={'ISO Training'} PageDescription={'Get yourself trained in ISO Standards'} />
        <section className="text-gray-600">
  <div className="max-w-screen-xl mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <img className="object-cover object-center rounded" alt="hero" src="/images/ISO-Training.jpeg"/>
    </div>
    <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-center">
      <h1 className=" sm:text-4xl text-3xl mb-4 font-medium text-amber-500">ISO Training Course</h1>
      <p className="mb-8 leading-relaxed">ISO Training CourseGlobus Certifications provides classroom (instructor-led) trainings in the following areas:</p>
      <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
      <div className="p-4 sm:mb-0 mb-6">
        <h2 className="text-xl font-medium  text-amber-500 mt-5">Overview of ISO Standards</h2>
        <p className="text-base leading-relaxed mt-2">This ISO training is designed to help you understand the key features of ISO standards including basic concepts, terminology and overview of standard requirements.</p>
      </div>
      <div className="p-4 sm:mb-0 mb-6">
        <h2 className="text-xl font-medium  text-amber-500 mt-5"> ISO Internal Auditor Training Course</h2>
        <p className="text-base leading-relaxed mt-2">This is an important ISO training course since the organisation needs an internal auditor to check for compliance. Internal audits are crucial since they help in finding areas of non-conformance before external audits are carried out.</p>
      </div>
    </div>
    </div>
  </div>
</section>
<Milestone/>
<CertAction/>
    </div>
  )
}

export default IsoTraining