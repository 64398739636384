import React from "react";
import { FaPhoneVolume } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Topbar = () => {
  return (
    <div className="bg-amber-500">
      <div className="container px-5 mx-auto flex justify-end items-center py-1.5 gap-2">
        <p className="font-medium text-white text-sm">
          <FaPhoneVolume color="white" className="inline-block mr-1 my-auto" />{" "}
          +91-9999763637, +91-9918728945
        </p>
        <span>|</span>
        <Link className="text-white font-medium hover:underline bg-red-500 px-2 rounded-md py-0.5 hover:bg-red-600" to={'/franchise'} >Apply Franchise</Link>
      </div>
    </div>
  );
};

export default Topbar;
