import axios from "axios";
import { useEffect, useState } from "react";

const useFetch = (endpoint, query, shouldFetchOnMount = true) => {
  const [FetchedData, setFetchedData] = useState([]);
  const [IsFetchLoading, setIsFetchLoading] = useState(false);
  const [FetchError, setFetchError] = useState(null);

  const FetchData = async () => {
    setIsFetchLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_GCERT_UI}${endpoint}`,{params: { ...query },}
      );
       console.log("Hook Response",response);
       setFetchedData(response.data);
    } catch (err) {
      console.error("Hook Fetch Error",err);
      setFetchError(err?.message);
    } finally {
      setIsFetchLoading(false);
    }
  };

  useEffect(() => {
    if(shouldFetchOnMount){
      FetchData();
    }
  }, [shouldFetchOnMount]);

  const Refetch = () => {
    FetchData();
  };

  return { FetchedData, IsFetchLoading, FetchError, Refetch };
};

export default useFetch;
