import React, { useState } from 'react'
import { Outlet } from 'react-router-dom';
import Sidebar from '../UiComponents/Sidebar';
import AdminHeader from '../UiComponents/AdminHeader';

const AdminLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="flex h-screen overflow-hidden">
    <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
      <AdminHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <main className="no-scrollbar overflow-auto">
        <div className="p-3  pb-10 w-full max-w-10xl mx-auto">
          <Outlet />
        </div>
      </main>
    </div>
  </div>
  )
}

export default AdminLayout