import React, { useState } from 'react'
import PageHeader from '../UiComponents/PageHeader'
import { Button, Input, Textarea } from '@chakra-ui/react'
import CertAction from '../UiComponents/CertAction'
import usePost from '../Utils/usePost'
import { LuBadgeCheck } from 'react-icons/lu'

const Franchise = () => {
  const { IsPostLoading: IsPostFranchiseLoading, PostData: PostFranchiseData } = usePost('/api/Franchise/SaveFrenchise');
  const { IsPostLoading: IsPostMailFranchiseLoading, PostData: PostMailFranchiseData } = usePost('/api/ContactUs/SendEmailBySendGrid');
 
  const [ShowMsg, setShowMsg] = useState(false);
  const [FranchiseData, setFranchiseData] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    fullAddress: "",
    phoneNumber: "",
    whatsappNumber: "",
    emailId: "",
    websiteUrl: "",
    qaContent: "",
    errorMessage: "",
    qa1:'',
    qa2:'',
    qa3:'',
    qa4:'',
    qa5:'',
    qa6:'',
  });

  const SaveFranchiseData = async (e) => {
    e.preventDefault();

    let body = {
      id: FranchiseData?.id,
      firstName: FranchiseData?.firstName,
      lastName: FranchiseData?.lastName,
      fullAddress: FranchiseData?.fullAddress,
      phoneNumber: FranchiseData?.phoneNumber,
      whatsappNumber: FranchiseData?.whatsappNumber,
      emailId: FranchiseData?.emailId,
      websiteUrl: FranchiseData?.websiteUrl,
      errorMessage: "",
      qaContent: `
      <div>
        <h6>Q1: From where you have heard about Globus?</h6>
        <p><b>${FranchiseData?.qa1}</b></p>
        <br/> 

        <h6>Q2: How many years of experience you have as an Individual or organization in the field of management system certification? Share a brief about you and your organization.</h6>
        <p><b>${FranchiseData?.qa2}</b></p>
        <br/> 

        <h6>Q3: What is your expectation from us?</h6>
        <p><b>${FranchiseData?.qa3}</b></p>
        <br/> 

        <h6>Q4: Do you have any prior experience working as ISO Certification Franchise, please share a brief of your experience?</h6>
        <p><b>${FranchiseData?.qa4}</b></p>
        <br/> 

        <h6>Q5: How much business we can expect from you in terms cost (Per year)?</h6>
        <p><b>${FranchiseData?.qa5}</b></p>
        <br/> 

        <h6>Q6: Any other details?</h6>
        <p><b>${FranchiseData?.qa6}</b></p>
        <br/> 
      </div>`,
    }

    const FormBody = new FormData();

    FormBody.append('EmailSubject','New Franchise Submission')
    FormBody.append('EmailBody',`<div>
    <p><b>Name: </b> ${FranchiseData?.firstName} ${FranchiseData?.lastName}<br/>
     <b>Address: </b> ${FranchiseData?.fullAddress}<br/>
     <b>Phone Number: </b> ${FranchiseData?.phoneNumber}<br/>
     <b>Whatsapp Number: </b> ${FranchiseData?.whatsappNumber}<br/>
     <b>Email Id: </b> ${FranchiseData?.emailId}<br/>
     <b>Website URL: </b> ${FranchiseData?.websiteUrl}<br/>
    </div>

    <div>
    <h6>Q1: From where you have heard about Globus?</h6>
    <p><b>${FranchiseData?.qa1}</b></p>
    <br/> 

    <h6>Q2: How many years of experience you have as an Individual or organization in the field of management system certification? Share a brief about you and your organization.</h6>
    <p><b>${FranchiseData?.qa2}</b></p>
    <br/> 

    <h6>Q3: What is your expectation from us?</h6>
    <p><b>${FranchiseData?.qa3}</b></p>
    <br/> 

    <h6>Q4: Do you have any prior experience working as ISO Certification Franchise, please share a brief of your experience?</h6>
    <p><b>${FranchiseData?.qa4}</b></p>
    <br/> 

    <h6>Q5: How much business we can expect from you in terms cost (Per year)?</h6>
    <p><b>${FranchiseData?.qa5}</b></p>
    <br/> 

    <h6>Q6: Any other details?</h6>
    <p><b>${FranchiseData?.qa6}</b></p>
    <br/> 
  </div>`)

    console.log("Franchise Body",body);

    const response = await PostFranchiseData(body);
    const response1 = await PostMailFranchiseData(FormBody)


    if(response1){
      setShowMsg(true);
      setTimeout(() => {
        setShowMsg(false);
      }, 3000);
      setFranchiseData({
        id: 0,
        firstName: "",
        lastName: "",
        fullAddress: "",
        phoneNumber: "",
        whatsappNumber: "",
        emailId: "",
        websiteUrl: "",
        qaContent: "",
        errorMessage: "",
        qa1:'',
        qa2:'',
        qa3:'',
        qa4:'',
        qa5:'',
        qa6:'',
      })
    }

  }
  return (
    <div>
        <PageHeader PageTitle={'ISO Franchise'} />
        <div className="max-w-screen-xl py-16 px-5 mx-auto ">
          <div className='mb-10 text-center'>
           <h1 className="text-3xl text-amber-500 font-bold mb-2">Business Partners</h1>
           <p className='text-gray-600'>All information applied here in shall be reserved solely for the purpose of applying for Franchise. None of the applicant’s personal information gathered here shall be disclosed to another party or person unless requested by law.</p>
          </div>

          {ShowMsg ? <div className='text-center bg-white bg-opacity-30 border-2 py-8 border-dashed rounded-3xl'>
          <div className='space-y-2 my-auto'>
            <LuBadgeCheck size={'6rem'} className='mx-auto text-green-500 animate-bounce'/>
            <h6 className='text-3xl font-bold text-gray-800'>Thank You!!</h6>
            <p className='text-gray-500'>We will get back to you as soon as possible.</p>
          </div>
        </div>:
          <form onSubmit={SaveFranchiseData} className='space-y-4 md:max-w-4xl mx-auto'>
          <div className='grid grid-cols-2 gap-4'>
            <div className="space-y-2">
              <label>First Name</label>
              <Input value={FranchiseData?.firstName || ''} onChange={({target})=>{
                setFranchiseData({
                  ...FranchiseData,
                  firstName: target.value
                })
              }} borderColor={'gray.400'} placeholder='First Name' />
            </div>
            <div className="space-y-2">
              <label>Last Name</label>
              <Input value={FranchiseData?.lastName || ''} onChange={({target})=>{
                setFranchiseData({
                  ...FranchiseData,
                  lastName: target.value
                });
              }} borderColor={'gray.400'} placeholder='Last Name' />
            </div>
            <div className="space-y-2">
              <label>Phone Number</label>
              <Input type='number' value={FranchiseData?.phoneNumber} onChange={({target})=>{
                setFranchiseData({
                  ...FranchiseData,
                  phoneNumber: target.value
                })
              }} borderColor={'gray.400'} placeholder='Phone Number' />
            </div>
            <div className="space-y-2">
              <label>WhatsApp Number</label>
              <Input type='number' value={FranchiseData?.whatsappNumber} onChange={({target})=>{
                setFranchiseData({
                  ...FranchiseData,
                  whatsappNumber: target.value
                })
              }} borderColor={'gray.400'} placeholder='WhatsApp Number' />
            </div>
            <div className="space-y-2">
              <label>Email</label>
              <Input type='email' value={FranchiseData?.emailId} onChange={({target})=>{
                setFranchiseData({
                  ...FranchiseData,
                  emailId: target.value
                })
              }} borderColor={'gray.400'} placeholder='Email' />
            </div>
            <div className="space-y-2">
              <label>Website</label>
              <Input value={FranchiseData?.websiteUrl} onChange={({target})=>{
                setFranchiseData({
                  ...FranchiseData,
                  websiteUrl: target.value
                })
              }} borderColor={'gray.400'} placeholder='Website' />
            </div>
          </div>
           <div className="space-y-2">
              <label>Address</label>
              <Textarea value={FranchiseData?.fullAddress} onChange={({target})=>{
                setFranchiseData({
                  ...FranchiseData,
                  fullAddress: target.value
                })
              }} borderColor={'gray.400'} placeholder='Address' />
            </div>
            <div className="space-y-2">
              <label>From where you have heard about Globus?</label>
              <Input value={FranchiseData?.qa1} onChange={({target})=>{
                setFranchiseData({...FranchiseData, qa1 : target.value});
              }} borderColor={'gray.400'} placeholder='From where you have heard about Globus?' />
            </div>
            <div className="space-y-2">
              <label>How many years of experience you have as an Individual or organization in the field of management system certification? Share a brief about you and your organization.</label>
              <Input value={FranchiseData?.qa2} onChange={({target})=>{
                setFranchiseData({...FranchiseData, qa2 : target.value});
              }} borderColor={'gray.400'} placeholder='Enter Answer' />
            </div>
            <div className="space-y-2">
              <label>What is your expectation from us?</label>
              <Input value={FranchiseData?.qa3} onChange={({target})=>{
                setFranchiseData({...FranchiseData, qa3 : target.value});
              }} borderColor={'gray.400'} placeholder='What is your expectation from us?' />
            </div>
            <div className="space-y-2">
              <label>Do you have any prior experience working as ISO Certification Franchise, please share a brief of your experience?</label>
              <Input value={FranchiseData?.qa4} onChange={({target})=>{
                setFranchiseData({...FranchiseData, qa4 : target.value});
              }} borderColor={'gray.400'} placeholder='Enter Answer' />
            </div>
            <div className="space-y-2">
              <label>How much business we can expect from you in terms cost (Per year)?</label>
              <Input value={FranchiseData?.qa5} onChange={({target})=>{
                setFranchiseData({...FranchiseData, qa5 : target.value});
              }} borderColor={'gray.400'} placeholder='How much business we can expect from you in terms cost (Per year)' />
            </div>
            <div className="space-y-2">
              <label>Any other details?</label>
              <Input value={FranchiseData?.qa6} onChange={({target})=>{
                setFranchiseData({...FranchiseData, qa6 : target.value});
              }} borderColor={'gray.400'} placeholder='Any other details?' />
            </div>
            <div className='text-center'>
              <Button isLoading={IsPostFranchiseLoading || IsPostMailFranchiseLoading} loadingText="Submitting..." type='submit' colorScheme='orange' >Submit</Button>
            </div>
          </form>}
        </div>
        <CertAction/>
    </div>
  )
}

export default Franchise