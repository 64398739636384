import React, { useState, useEffect, useRef } from "react";
import {LuFileBadge, LuHome, LuMails} from 'react-icons/lu';
import { NavLink, useLocation } from "react-router-dom";
import { IoFileTrayFullOutline } from "react-icons/io5";
import { GrAnnounce } from "react-icons/gr";

function Sidebar({ sidebarOpen, setSidebarOpen }) {

  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);



  return (
    <div>
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col  mb-8  top-0 absolute z-40 left-0  lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-60 md:sidebar-expanded:!w-60 lg:w-60 lg:sidebar-expanded:!w-60 2xl:!w-60 shrink-0 bg-blue-700 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-72"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2 bg-slate-100 p-1 drop-shadow-md rounded-xl">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}

          <NavLink end to="/admin/addcertificate" className="block">
            <div className="flex justify-center gap-2 items-center">
              <h1 className="text-gray-800 font-bold ">
                Globus Certifications
              </h1>
            </div>
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>

            <ul className=" space-y-2">
                  {/* Dashboard */}
                  {/* <li
                    className={`px-3 py-2 rounded-xl hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("/dashboard") &&
                      "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/admin/dashboard"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/dashboard") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <LuHome size="16px" />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Dashboard
                        </span>
                      </div>
                    </NavLink>
                  </li> */}

                  <li
                    className={`px-3 py-2 rounded-xl hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("addcertificate") &&
                      "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/admin/addcertificate"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("addcertificate") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <LuFileBadge size="16px" />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Certificates
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  <li
                    className={`px-3 py-2 rounded-xl hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("addservice") &&
                      "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/admin/addservice"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("addservice") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <IoFileTrayFullOutline size="16px"/>
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Services
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {/* <li
                    className={`px-3 py-2 rounded-xl hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("addfraudulantactivitynotice") &&
                      "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/admin/addFraudulantActivityNotice"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("addfraudulant") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <LuFileBadge size="16px" />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Faudulant Activity Notice
                        </span>
                      </div>
                    </NavLink>
                  </li> */}

                  <li
                    className={`px-3 py-2 rounded-xl hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("announcements") &&
                      "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/admin/announcements"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("announcements") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <GrAnnounce size="16px" />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Announcements
                        </span>
                      </div>
                    </NavLink>
                  </li>

            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:hidden 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
