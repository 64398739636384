import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination,Autoplay } from 'swiper/modules';
const HeroSection = () => {

    const SliderData = [
        {
            imageURL: '/images/medical.jpg',
            imageAltText: 'Medical Devices Quality Management Systems',
            sliderTitle: "ISO 13485:2016 – Medical Devices Quality Management Systems (MDQMS)"
        },
        {
            imageURL: '/images/foodManagement.jpg',
            imageAltText: 'Food Safety Management Systems',
            sliderTitle: "ISO 22000:2018 – Food Safety Management System (FSMS)"
        },
        {
            imageURL: '/images/energyManagement.jpg',
            imageAltText: 'Energy Management Systems',
            sliderTitle: "ISO 50001:2018 – Energy Management Systems"
        },
        {
            imageURL: '/images/qualityManagement.jpg',
            imageAltText: 'Quality Management Systems',
            sliderTitle: "ISO 9001:2015 – Quality Management Systems (QMS)"
        },
        // {
        //     imageURL: '/images/environmentManagement.jpg',
        //     imageAltText: 'Environment Management Systems',
        //     sliderTitle: "ISO 9001:2015 – Environment Management Systems (EMS)"
        // },
        {
            imageURL: '/images/capabilityMaturity.jpg',
            imageAltText: 'Capability Maturity Model (CMMi)',
            sliderTitle: "Capability Maturity Model (CMMi)"
        },
        // {
        //     imageURL: '/images/hrServices.jpg',
        //     imageAltText: 'Human Resource Services',
        //     sliderTitle: "Human Resource Services"
        // },
        {
            imageURL: '/images/cyberSecurity.jpg',
            imageAltText: 'Cyber Security Services',
            sliderTitle: "Cyber Security Services"
        },
        {
            imageURL: '/images/informationSecurity.jpg',
            imageAltText: 'Information Security Management Systems',
            sliderTitle: "ISO 27001:2013 – Information Security Management Systems (ISMS)"
        },
    ]
  return (
  <Swiper 
    loop={true}
    pagination={{
      clickable: true,
    }}
    centeredSlides={true}
    autoplay={{
      delay: 2000,
      disableOnInteraction: false
    }}
    modules={[Pagination,Autoplay]}
  >
    {SliderData?.map((data,i)=>{
        return <SwiperSlide key={i}>
        <div className="relative hover-img md:h-[36rem] h-96 overflow-hidden">
            <img
              className="max-w-full w-full mx-auto h-full object-cover"
              src={data?.imageURL}
              alt={data?.imageAltText}
            />
          <div className="absolute px-6 pt-8 pb-4 bottom-0 w-full bg-gradient-to-t from-black">
              <h2 className="text-lg md:text-3xl font-bold text-white">
               {data?.sliderTitle}
              </h2>
          </div>
        </div>
       </SwiperSlide>
    })}
  </Swiper>
  )
}

export default HeroSection