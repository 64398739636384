import React from 'react'
import PageHeader from '../UiComponents/PageHeader'

const FraudulantActivityNotice = () => {
  return (
    <div>
      <PageHeader PageTitle={'Fraudulant Activity Notice'} />
    </div>
  )
}

export default FraudulantActivityNotice