import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import DropdownMenu from './DropdownMenu';
import { useBoolean } from '@chakra-ui/react';

const Header = () => {

  const [IsMenuOpen, setIsMenuOpen] = useBoolean();

  const aboutMenuItems = [
    { label: 'Our Policy', action: 'link', labelPath : '/our-policy' },
    { label: 'Our Accreditation', action: 'link', labelPath : '/our-accreditation' },
    // { label: 'Our Accreditation', action: 'pdf', labelPath:'/documents/UAF-Accreditation-Certificate-Globus.pdf' },
  ];

  const trainingMenuItems = [
    { label: 'ISO Certification', action: 'link', labelPath: '/iso-certification' },
    { label: 'Six Sigma Training', action: 'link', labelPath: '/lean-training' },
  ];

  const directoryMenuItems = [
    { label: 'Certification Status', action: 'link', labelPath: '/certification-status' },
    // { label: 'Franchise', action: 'link', labelPath: '/franchise' },
  ];

  const processMenuItems = [
    { label: 'Planning Audits', action: 'pdf', labelPath:'/documents/New Planning Audits.pdf' },
    { label: 'Conducting Audits', action: 'pdf', labelPath:'/documents/New Conducting Audits.pdf' },
    { label: 'Taking Certification Decisions', action: 'pdf', labelPath:'/documents/New Taking Certification Decisions.pdf' },
    { label: 'Maintaining Certifications', action: 'pdf', labelPath:'/documents/New Maintaining Certification.pdf' },
    { label: 'Handling Appeals', action: 'pdf', labelPath:'/documents/New Handling Appeals.pdf' },
    { label: 'Handling Complaints', action: 'pdf', labelPath:'/documents/New Handling Complaints.pdf' },
    { label: 'Initial Certification', action: 'pdf', labelPath:'/documents/New Initial Certification.pdf' },
    { label: 'Pre Certification Activities', action: 'pdf', labelPath:'/documents/New Pre Certification Activities.pdf' },
    { label: 'Impartiality Policy', action: 'pdf', labelPath:'/documents/New Management of Impartiality.pdf' },
    { label: 'Agreement Use of Logo & Marks', action: 'pdf', labelPath:'/documents/New Agreement Use of Logo & marks.pdf' },
  ];

  return (
    <header className="text-gray-600 ">
      <div className="max-w-screen-xl mx-auto flex flex-wrap py-2 flex-row items-center justify-between px-2">
        <Link to={'/'} className="flex items-center gap-2 justify-start">
          <img src="/images/globusLogo.png" className='h-20' alt="globus logo" />
          {/* <h1 className='text-xl font-bold text-gray-800'>Globus Certifications</h1> */}
        </Link>

        <nav className=" hidden md:ml-auto md:flex flex-wrap items-center text-base justify-center space-x-4">
          <NavLink to={'/'} className={({ isActive }) =>"font-semibold hover:text-amber-500 " + (isActive ? "text-amber-500" : "text-gray-800")}>Home</NavLink>
          <DropdownMenu buttonLabel="About Us" mainMenuPath={'/about-us'} submenuItems={aboutMenuItems} />
          <NavLink to={'/iso-certifications'} className={({ isActive }) =>"font-semibold hover:text-amber-500 " + (isActive ? "text-amber-500" : "text-gray-800")}>Services</NavLink>
          <DropdownMenu buttonLabel="Process" mainMenuPath={'/iso-certification-process'} submenuItems={processMenuItems} />
          {/* <NavLink to={'/'} className={({ isActive }) =>"font-semibold hover:text-amber-500" + (isActive ? "text-amber-500" : "text-gray-800")}>Industry</NavLink> */}
          <DropdownMenu buttonLabel="Trainings" mainMenuPath={'/trainings'} submenuItems={trainingMenuItems} />
          <DropdownMenu buttonLabel="Directory" mainMenuPath={"/undefined"} submenuItems={directoryMenuItems} />
          <NavLink to={'/contact-us'} className={({ isActive }) =>`${isActive ? "text-amber-500" : "text-gray-800"} font-semibold hover:text-amber-500`}>Contact Us</NavLink>
          <a href='http://ec2-13-200-213-8.ap-south-1.compute.amazonaws.com:5000/login' target='_blank' rel="noreferrer"  className={`text-gray-50 bg-red-500 py-1 px-2 rounded-lg font-semibold hover:bg-red-600`}>Client/Auditor Login</a>
        </nav>

        <svg className={`md:hidden ham hamRotate ham8 ${IsMenuOpen ? 'active' : ''} w-12 `} viewBox="0 0 100 100"  onClick={()=>{setIsMenuOpen.toggle();}}>
         <path className="line top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
         <path className="line middle" d="m 30,50 h 40" />
         <path className="line bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
        </svg>
      </div>

      <nav className={`${IsMenuOpen ? 'flex' : 'hidden'} md:ml-auto md:hidden flex flex-col items-center text-base justify-start space-y-2`}>
        <NavLink to={'/'} className={({ isActive }) =>"font-semibold hover:text-amber-500 " + (isActive ? "text-amber-500" : "text-gray-800")}>Home</NavLink>
        <DropdownMenu buttonLabel="About Us" mainMenuPath={'/about-us'} submenuItems={aboutMenuItems} />
        <NavLink to={'/iso-certifications'} className={({ isActive }) =>"font-semibold hover:text-amber-500 " + (isActive ? "text-amber-500" : "text-gray-800")}>Services</NavLink>
        <DropdownMenu buttonLabel="Process" mainMenuPath={'/iso-certification-process'} submenuItems={processMenuItems} />
        {/* <NavLink to={'/'} className={({ isActive }) =>"font-semibold hover:text-amber-500" + (isActive ? "text-amber-500" : "text-gray-800")}>Industry</NavLink> */}
        <DropdownMenu buttonLabel="Trainings" mainMenuPath={'/trainings'} submenuItems={trainingMenuItems} />
        <DropdownMenu buttonLabel="Directory" mainMenuPath={"/undefined"} submenuItems={directoryMenuItems} />
        <NavLink to={'/contact-us'} className={({ isActive }) =>`${isActive ? "text-amber-500" : "text-gray-800"} font-semibold hover:text-amber-500`}>Contact Us</NavLink>
        <a href='http://ec2-13-200-213-8.ap-south-1.compute.amazonaws.com:5000/login' target='_blank' rel="noreferrer"  className={`text-gray-800 font-semibold hover:text-amber-500`}>Client/Auditor Login</a>
      </nav>
    </header>
  )
}

export default Header