import React, { useState } from "react";
import PageHeader from "../UiComponents/PageHeader";
import { Button, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { LuSearch } from "react-icons/lu";
import axios from "axios";
import CertAction from "../UiComponents/CertAction";
import { TbCertificate2Off } from "react-icons/tb";
import { CertificationStatusSchema } from "../Utils/FormValidation";

const CertStatus = () => {
  const [IsLoading, setIsLoading] = useState(false);
  const [CertNumber, setCertNumber] = useState();
  const [CertDetails, setCertDetails] = useState();
  const [FormErrors, setFormErrors] = useState({});

  const GetCertificationDetails = async () => {
    setFormErrors({});
    CertificationStatusSchema.validate({
      certNumber: CertNumber
    },{abortEarly:false})
    .then(async() => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_GCERT_UI}/api/Certificate/GetCertificateByNo?SaveCertificate=${CertNumber}`
      );
      console.log("Cert Details", response);
      setCertDetails(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }

  }).catch((validationErrors) => {
    const newErrors = {};
    validationErrors.inner.forEach((error) => {
      newErrors[error.path] = error.message;
    });
    setFormErrors(newErrors);
  });
  };
  return (
    <div>
      <PageHeader PageTitle={"Certification Status"} />
      <div className="max-w-screen-xl py-16 px-5 mx-auto text-center">
        <h1 className="text-3xl text-amber-500 font-bold mb-10">Check Certification Status</h1>
        <p className="mb-8 leading-relaxed">
        Globus is committed to maintaining the online status of all the certificates issued. 
Globus client online information provides the current status (Active, Suspension, Hold or Withdrawal) of a certified client.The information will appear to you with the entry of the certificate number.
<br></br>Should you encounter any problems, please contact us at email: <a className="font-bold text-gray-800" href="mailto:info@gcert.co">info@gcert.co</a>. We will respond after reviewing your inquiry.

        </p>
        <div className="flex gap-6 justify-center mb-8">
          <div className="w-full lg:w-1/4">
            <FormControl variant={"floating"}>
              <Input
                isInvalid={FormErrors?.certNumber}
                value={CertNumber || ""}
                onChange={({ target }) => {
                  setCertNumber(target.value);
                }}
                borderColor={"gray.400"}
                placeholder=""
              />
              <FormLabel>Enter Certificate Number</FormLabel>
              {FormErrors?.certNumber && <span className="inline-flex justify-start text-red-600">{FormErrors?.certNumber}</span>}
            </FormControl>
          </div>
          <Button
            isLoading={IsLoading}
            isDisabled={!CertNumber}
            loadingText={"Searching..."}
            onClick={() => {
              GetCertificationDetails();
            }}
            leftIcon={<LuSearch />}
            colorScheme="orange"
          >
            Search
          </Button>
        </div>

        {CertDetails && (
          <>{CertDetails?.errorMessage === null ? 
             <div className="relative overflow-x-auto rounded-2xl w-full md:w-2/3 mx-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <tbody>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Name of the company
                  </th>
                  <td className="px-6 py-4">{CertDetails?.organization}</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Company Address
                  </th>
                  <td className="px-6 py-4">{CertDetails?.address}</td>
                </tr>
                <tr className="bg-white border-b ">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Certificate Number
                  </th>
                  <td className="px-6 py-4">{CertDetails?.certificate}</td>
                </tr>
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Certification Standard
                  </th>
                  <td className="px-6 py-4">{CertDetails?.standard}</td>
                </tr>
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Scope of the certification
                  </th>
                  <td className="px-6 py-4">{CertDetails?.description}</td>
                </tr>
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Issuing Date
                  </th>
                  <td className="px-6 py-4">{CertDetails?.issueDate}</td>
                </tr>
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Expiry Date
                  </th>
                  <td className="px-6 py-4">{CertDetails?.expiryDate}</td>
                </tr>
                <tr className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                  >
                    Status
                  </th>
                  <td className="px-6 py-4">
                    <span
                      className={`${
                        CertDetails?.status === "Active"
                          ? "bg-green-500"
                          : CertDetails?.status === "Suspend"
                          ? "bg-red-500"
                          : CertDetails?.status === "Hold" ? "bg-yellow-500"
                          :  CertDetails?.status === "Withdrawl" ? "bg-blue-500" : "bg-orange-500"
                      } text-white rounded-md px-2 py-0.5 font-medium`}
                    >
                      {CertDetails?.status}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div> : CertDetails?.errorMessage === "Record not found!" ? <div className="text-center space-y-2">
            <TbCertificate2Off size={'8rem'} className="mx-auto text-red-400"/>
            <h6 className="text-lg font-bold text-gray-800">Sorry, we are unable to find your Certificate Details!!</h6>
            <p className="text-lg font-medium text-gray-600">Please contact on - info@gcert.co</p>
          </div> : ''}
          </>
        )}
      </div>
      <CertAction/>
    </div>
  );
};

export default CertStatus;
