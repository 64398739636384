import React from 'react'
import { Avatar, Button } from '@chakra-ui/react';
import HeroSection from './HeroSection';
import { MdOutlineRequestQuote } from "react-icons/md";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { TbCertificate } from "react-icons/tb";
import CertAction from '../UiComponents/CertAction';
import { FiFileText } from "react-icons/fi";
import { GoFileBinary } from "react-icons/go";
import { LuFileBadge,LuFileCheck  } from "react-icons/lu";
import { AiOutlineAudit } from "react-icons/ai";
import useFetch from '../Utils/useFetch';

const HomePage = () => {

  const { FetchedData: FetchedFeaturedServList } = useFetch('/api/Service/GetFeaturedList');

  const HowToGetCertData = [
    {
      cardIcon:  <FiFileText size="24px"/>,
      cardTitle : 'Application',
      cardDescription: 'Submit your initial application form providing essential information about your organization and the certification you are seeking.'
    },
    {
      cardIcon:  <MdOutlineRequestQuote size="24px"/>,
      cardTitle : 'Quotation',
      cardDescription: 'Receive a detailed quotation outlining the costs associated with the certification process, including assessment and audit fees.'
    },
    {
      cardIcon:  <GoFileBinary size="24px"/>,
      cardTitle : 'Stage 1 Audit',
      cardDescription: "Undergo an initial assessment to evaluate your organization's readiness for certification, including a review of documentation and processes."
    },
    {
      cardIcon:  <LuFileCheck size="24px"/>,
      cardTitle : 'Stage 2 Audit',
      cardDescription: "Engage in a comprehensive on-site audit to assess the implementation and effectiveness of your management system in meeting certification requirements."
    },
    {
      cardIcon:  <LuFileBadge size="24px"/>,
      cardTitle : 'Certification',
      cardDescription: "Upon successful completion of audits, receive official certification acknowledging your organization's compliance with the specified standards."
    },
    {
      cardIcon:  <AiOutlineAudit size="24px"/>,
      cardTitle : 'Survelliance Audit',
      cardDescription: 'Participate in periodic surveillance audits to ensure ongoing conformity and improvement, demonstrating your commitment to maintaining certification standards.'
    }
  ]


  const IsoCertServData = [
    {
      servTitle: '9001:2015 QMS',
      servDescription: 'ISO 9001 is the international standard that specifies requirements for a quality management system (QMS). The ISO 9001:2015 standard enables organizations to consistently provide products and services that meet customer and regulatory requirements.&nbsp;ISO 9001:2015 is applicable to any organization, regardless of size or industry.'
    },
    {
      servTitle: '14001:2015 EMS',
      servDescription: 'ISO 14000 is a family of standards related to environmental management that enables organizations to minimize how their operations negatively affect the environment (i.e. cause adverse changes to air, water, or land); comply with applicable laws, regulations, and other environmentally oriented requirements; and continually improve in the above.'
    },
    {
      servTitle: '22000:2018 FSMS',
      servDescription: 'ISO 22000:2018 or Food Safety Management System (FSMS) consists of  requirements for a food safety management system. It defines what an organization needs to do to demonstrate its ability to control food safety hazards in order to ensure that food is safe. ISO 22000 integrates the principles of the Hazard Analysis and Critical Control Point (HACCP) system.'
    },
    {
      servTitle: 'ISO 27001:2022',
      servDescription: 'Information security management system (ISMS) or the ISO/IEC 27000 family of standards helps organizations keep information assets secure. The standard helps your organization manage the security of assets such as financial information, intellectual property, employee details or information entrusted to you by third parties.'
    },
    {
      servTitle: '45001:2018 OH&S',
      servDescription: 'ISO 45001:2018 specifies requirements for an occupational health and safety (OH&S) management system, to enable organizations to provide safe and healthy workplaces by preventing work-related injury and ill health, as well as by proactively improving its OH&amp;S performance. The goal of OH&amp;S is the reduction of occupational injuries and diseases.'
    },
    {
      servTitle: '20000-1:2011 - ITSM',
      servDescription: 'ISO 20000-1:2011 provides a set of standardised requirements for an IT service management system (ITSM). The standard enables that both internal and external IT services are aligned with the needs of your business and customers. ITSM ensures that your day to day service delivery is carried out in a way that drives customer satisfaction through improved service and leads.'
    },
    {
      servTitle: '50001:2011 EnMS',
      servDescription: 'ISO 50001:2011 Energy management systems is a standard that specifies the requirements for establishing, implementing, maintaining and improving an energy management system.  It enables an organization to follow a systematic approach in achieving continual improvement of energy performance.'
    },
    {
      servTitle: '22301:2012 BCM',
      servDescription: 'ISO 22301 or Business continuity management systems is a standard that specifies requirements to plan, establish, implement, operate, monitor, review, maintain and continually improve a documented management system to protect against, reduce the likelihood of occurrence, prepare for, respond to, and recover from disruptive incidents when they arise.'
    },
  ]


  return (
    <div>

      
      <HeroSection/>


      <section className="text-gray-600 bg-slate-50 ">
        <div className="max-w-screen-xl mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className=" sm:text-4xl text-3xl mb-4 font-bold text-amber-500">
              Who are we?
            </h1>
            <p className="mb-8 leading-relaxed">
              Globus Certifications Pvt Ltd. (henceforth GCPL) is an independent
              certification body, which provides ISO certification to
              organizations across the globe. Additionally, we also provide
              trainings in ISO, Lean, Six Sigma and other quality methodologies.
              Our mission is be a leading certification body globally. <br/><br/> We, at
              GCPL have more than 16 years of industrial experience as a team
              and can cater to all kinds of industries for their certification
              needs. <br/><br/> We are accredited by UAF (United Accreditation Foundation)
              for ISO 9001:2015, ISO 14001:2015, OHSAS 18001:2007 and ISO
              45001:2018 standards. UAF is a full member of IAF (International
              Accreditation Forum). IAF is the world association of Conformity
              Assessment Accreditation Bodies and other bodies interested in
              conformity assessment in the fields of management systems,
              products, services, personnel and other similar programmes of
              conformity assessment. 
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center h-full w-full rounded"
              alt="hero"
              src="/images/Who-We-Are.jpeg"
            />
          </div>
        </div>
      </section>
      <section className="text-gray-600 ">
  <div className="max-w-screen-xl px-5 py-16 mx-auto">
    <div className='text-center mb-10'>
    <h1 className="sm:text-3xl text-2xl font-bold text-amber-500 animate-fade">ISO CERTIFICATION SERVICES</h1>
    <p className='text-gray-400'>We offer certification services in multiple standards.</p>
    </div>

    <Swiper 
      slidesPerView={1} 
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }} 
      pagination={{
        clickable: true
      }}
      modules={[Pagination,Autoplay]} 
      loop={true} 
      grabCursor={true}
      breakpoints={{
        640: {
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      }}
    >
      {FetchedFeaturedServList?.map((data,i)=>{
       return <SwiperSlide key={i} className='mb-3'>
                <div className="p-4 flex">
                  <Avatar size={'lg'} src='/images/isologo.png' />
                  <div className="flex-grow pl-6">
                    <h2 className="text-rose-500 text-xl font-semibold mb-2">{data?.featuredServiceHeading}</h2>
                    <p className="leading-relaxed text-sm">{data?.featuredServiceContent}</p>
                  </div>
                </div>
              </SwiperSlide>
      })}
    </Swiper>
  </div>
</section>

{/* <div className=" bg-gray-50 flex items-center">
	<section className="bg-cover bg-center py-16 w-full" style={{backgroundImage : `url('/images/banner1.jpg')`}}>
		<div className="container mx-auto text-left text-white">
			<div className="flex flex-wrap md:flex-nowrap items-center">
				<div className="w-1/2">
					<h1 className="text-5xl font-medium mb-6">About <span className='text-amber-400'>Globus</span></h1>
					<p className="text-xl mb-6">Globus Certifications Pvt Ltd. (henceforth GCPL) is an independent certification body, which provides ISO certification to organizations across the globe. We also provide ISO, Six Sigma, Lean, Agile training.</p>
          <Link to={'/about-us'}>
           <Button variant={'link'} colorScheme='yellow' rightIcon={<FiArrowRight/>}>Learn More</Button>
          </Link>
				</div>
				<div className="w-1/2 pl-16">
					<img src="/images/landingAbout.jpg" className="h-64 w-full object-cover rounded-xl" alt="globus certification"/>
      </div>
				</div>
			</div>
	</section>
</div> */}

      <section className="text-gray-600 bg-slate-50">
  <div className="max-w-screen-xl px-5 py-16 mx-auto">
    <div className="flex flex-wrap w-full mb-10 flex-col items-center text-center">
      <h1 className="sm:text-3xl text-2xl font-bold   mb-2 text-amber-500">HOW TO GET CERTIFICATION ?</h1>
      <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">The solutions we provide are also globally recognized with the requirements of various national and international standards.</p>
    </div>
    <div className="flex flex-wrap -m-4">
      {HowToGetCertData.map((data,i) => (
            <div key={i} className="xl:w-1/3 md:w-1/2 p-4">
              <div className="border border-gray-200 p-6 h-full rounded-lg space-y-2">
                <Avatar bg='red.400' icon={data.cardIcon} />
                <h2 className="text-lg text-gray-900 font-medium   ">{data.cardTitle}</h2>
                <p className="leading-relaxed text-sm">{data.cardDescription}</p>
              </div>
            </div>
      ))}
    </div>
    <div className='text-center mt-6'>
      <Link to={'/iso-certification-process'}>
       <Button colorScheme='orange'>Know More</Button>
      </Link>
    </div>
  </div>
</section>


{/* <section className="text-gray-600 body-font">
  <div className="max-w-screen-xl px-5 py-24 mx-auto">
  <div className="flex flex-wrap w-full mb-10 flex-col items-center text-center">
      <h1 className="sm:text-3xl text-2xl font-bold   mb-2 text-amber-500">ISO Standard Certification by Globus Certifications</h1>
      <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">Globus Certifications is an independent certification body accredited by United Accreditation Foundation (UAF) for QMS, EMS and OHSMS standards to offer ISO certifications services to organisations across industries. We also conduct training for ISO, Lean, Six Sigma, and Agile.</p>
      <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">We DO NOT provide any kind of consulting services.</p>
    </div>

    <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
     <Avatar size={'2xl'}  bg='yellow.100' icon={<TbCertificate className='text-amber-500' size={'4rem'} />} className='mr-10' />
      <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  space-y-2">
        <h2 className="text-gray-900 text-lg  font-medium ">Get ISO Certification – Quality Management Systems</h2>
        <p className="leading-relaxed text-sm">We, at Globus Certifications, help you certify Quality Management Systems (QMS) within your organisation for improved business performance. Our expert and skilled auditors audit your system as per the ISO certification standards.</p>
        
      </div>
    </div>
    <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
      <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  space-y-2">
        <h2 className="text-gray-900 text-lg  font-medium ">Get ISO Certification</h2>
        <p className="leading-relaxed text-sm">We offers certification services to organisations. Established in 2009, we have helped numerous clients across the globe achieve their desired results. Get in touch with us if you want to get ISO Certification.</p>
        
      </div>
      <Avatar size={'2xl'}  bg='yellow.100' icon={<TbCertificate className='text-amber-500' size={'4rem'} />} className='ml-10' />
    </div>
    <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
     <Avatar size={'2xl'}  bg='yellow.100' icon={<TbCertificate className='text-amber-500' size={'4rem'} />} className='mr-10' />
      <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0 space-y-2">
        <h2 className="text-gray-900 text-lg  font-medium ">ISO Certifications Application and Registration Process</h2>
        <p className="leading-relaxed text-sm">We have established ourselves as one of the leading ISO certification providers in India and other parts of the world. We provide ISO Certification services for standards such as ISO 9001, ISO 14001, ISO 45001. We have assisted numerous clients in leading successful initiatives for ISO certification in various industries. Contact us to know the ISO Certification process now!</p>
        
      </div>
    </div>
    <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
      <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  space-y-2">
        <h2 className="text-gray-900 text-lg  font-medium ">Benefits of ISO Certification</h2>
        <p className="leading-relaxed text-sm">ISO certification guarantees that organisations, both big and small, are ready to compete at a global level when it comes to quality standards for products and services. This also helps in increasing the credibility of your organisation in the market since it follows a global standard. The constant assessment and improvement of the processes assures high-quality products and services. And, customer satisfaction, which is at the core of ISO standards ensures that you retain your current customers and win new ones.</p>
        
      </div>
      <Avatar size={'2xl'}  bg='yellow.100' icon={<TbCertificate className='text-amber-500' size={'4rem'} />} className='ml-10' />
    </div>
    <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
     <Avatar size={'2xl'}  bg='yellow.100' icon={<TbCertificate className='text-amber-500' size={'4rem'} />} className='mr-10' />
      <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0 space-y-2">
        <h2 className="text-gray-900 text-lg  font-medium ">Global ISO Certification Services</h2>
        <p className="leading-relaxed text-sm">Globus Certification offers ISO certification services to organisations in different industries. Our vision is to be a leading certification body globally. We have accreditation from UAF and can certify organizations on QMS, EMS and OHSAS standards in more than 30 countries. Contact us for reliable ISO certification services.</p>
        
      </div>
    </div>
    <div className="flex items-center lg:w-3/5 mx-auto mb-10 border-gray-200 sm:flex-row flex-col">
      <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0  space-y-2">
        <h2 className="text-gray-900 text-lg  font-medium ">ISO Certification Training in India</h2>
        <p className="leading-relaxed text-sm">Whether you want to achieve international certification or set up management systems for quality assurance of products or services, getting ISO training is going to help you achieve the desired results. We offer ISO training services to help organisations get an overview and scope of ISO.</p>
        
      </div>
      <Avatar size={'2xl'} bg='yellow.100' icon={<TbCertificate className='text-amber-500' size={'4rem'} />} className='ml-10' />
    </div>
  </div>
</section> */}

<CertAction/>

    </div>
  )
}

export default HomePage