import React from 'react'
import { LuBuilding2, LuUsers2 } from "react-icons/lu";
import { TbCertificate } from "react-icons/tb";
const Milestone = () => {
  return (
    <section className="text-gray-600 ">
    <div className="max-w-screen-xl px-5 py-16 mx-auto">
      <div className="flex flex-wrap -m-4 text-center">
        <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
          <div className="border-2 border-gray-200 px-4 py-6 rounded-3xl">
            <LuBuilding2 className="text-amber-500 w-14 h-14 mb-3 inline-block" />
            <h2 className=" font-bold text-3xl text-gray-800">2009</h2>
            <p className="leading-relaxed text-gray-400">Established In</p>
          </div>
        </div>
        <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
          <div className="border-2 border-gray-200 px-4 py-6 rounded-3xl">
            <LuUsers2 className="text-amber-500 w-14 h-14 mb-3 inline-block" />
            <h2 className=" font-bold text-3xl text-gray-800">1000+</h2>
            <p className="leading-relaxed text-gray-400">Clients World Wide</p>
          </div>
        </div>
        <div className="p-4 md:w-1/3 sm:w-1/2 w-full">
          <div className="border-2 border-gray-200 px-4 py-6 rounded-3xl">
            <TbCertificate className="text-amber-500 w-14 h-14 mb-3 inline-block" />
            <h2 className=" font-bold text-3xl text-gray-800">1000+</h2>
            <p className="leading-relaxed text-gray-400">Certificate Provided</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Milestone