import Header from "./UiComponents/Header";
import Footer from "./UiComponents/Footer";
import Topbar from "./UiComponents/Topbar";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import WhatsAppContact from "./UiComponents/WhatsAppContact";
import useFetch from "./Utils/useFetch";

function AppLayout() {

  const location = useLocation();
  const { FetchedData: FetchedAnnouncementList, } = useFetch('/api/Announcement/GetAnnouncementList');


  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]);

  return (
    <>
      <Topbar />
      <Header />
      <div className='bg-amber-500'>
        <div className='max-w-screen-2xl mx-auto'>
            <div className='flex'>
                <div className="news-ticker whitespace-nowrap text-right overflow-hidden bg-amber-500 text-white font-medium w-full flex gap-4">
                  {FetchedAnnouncementList?.filter(data=> data.isActive)?.map((data)=>{
                    return <ul className='inline-block list-none mr-6' key={data?.id} >{data?.contentDetail}</ul>
                  })}
                </div>
            </div>
        </div>
      </div>
      <Outlet />
      <Footer />
      <div className="absolute animate-bounce" style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
        <WhatsAppContact />
      </div>    </>
  );
}

export default AppLayout;
