import React from 'react'
import { TbCertificate2, TbChecklist } from "react-icons/tb";
import { HiOutlineDownload } from "react-icons/hi";
import { Link } from 'react-router-dom';

const CertAction = () => {

  const { origin } = window.location;

  return (
    <div className='max-w-screen-xl px-5 py-16 mx-auto'>
        <div className='flex justify-center gap-6'>
            <Link to={'/sample-certificates'} className='text-center cursor-pointer hover:bg-gray-100 p-4 rounded-xl'>
              <TbCertificate2 size={'5rem'} className='inline-block text-amber-500 mb-2' />
              <p className='text-gray-800 font-semibold'>Check Sample Certificates</p>
            </Link>
            <a href={`${origin}/documents/F-9.1.1A-Application-Form.docx`} download className='text-center cursor-pointer hover:bg-gray-100 p-4 rounded-xl'>
              <HiOutlineDownload size={'5rem'} className='inline-block text-amber-500 mb-2' />
              <p className='text-gray-800 font-semibold'>Download Application Form</p>
            </a>
            <Link to={'/certification-status'} className='text-center cursor-pointer hover:bg-gray-100 p-4 rounded-xl'>
              <TbChecklist size={'5rem'} className='inline-block text-amber-500 mb-2' />
              <p className='text-gray-800 font-semibold'>Check Certificate Status</p>
            </Link>
        </div>
    </div>
  )
}

export default CertAction