import React, { useState, useMemo, useCallback } from "react";
import { FiPlus,FiEdit } from 'react-icons/fi';
import { Button, Center, Spinner, Switch, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,Textarea, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import usePost from '../Utils/usePost';
import useFetch from '../Utils/useFetch';
import DatePicker from "react-datepicker";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";

const Announcements = () => {

  const { IsPostLoading: IsAddAnnouncementLoading, PostData: PostAddAnnouncementData } = usePost('/api/Announcement/SaveAnnouncement');
  const { IsFetchLoading: IsAnnouncementListLoading, FetchedData: FetchedAnnouncementList, Refetch: RefetchAnnouncementList } = useFetch('/api/Announcement/GetAnnouncementList');
  const { isOpen: isAnnouncementOpen, onOpen: onAnnouncementOpen, onClose: onAnnouncementClose } = useDisclosure();

  const [AnnouncementData, setAnnouncementData] = useState({
    id: 0,
    contentDetail: "",
    startDate: "",
    endDate: "",
    isActive: true
  })

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Start Date",
      field: "startDate",
      width:120,
      cellRenderer:(params) => moment(params.data.startDate).format('DD-MMM-YYYY')
    },
    {
      headerName: "End Date",
      field: "endDate",
      width:120,
      cellRenderer:(params) => moment(params.data.endDate).format('DD-MMM-YYYY')
    },
    {
      headerName: "Content",
      field: "contentDetail",
      width: 900
    },
    {
      headerName: "Active",
      field: "isActive",
      cellRenderer:({data}) => <Switch 
       isChecked={data.isActive} 
       onChange={async({target})=>{
        let body = {
          ...data,
          isActive: target.checked
        };
        console.log("Switch body",body);
        const response = await PostAddAnnouncementData(body);

        if(response) {
          RefetchAnnouncementList();
        }
       }}
       colorScheme="green" 
       />
    },
    {
      headerName: "Action",
      pinned: 'right',
      cellRenderer: ({data}) => <Button onClick={()=>{
        setAnnouncementData({
          ...data,
          startDate: new Date(data.startDate),
          endDate: new Date(data.endDate),
        });
        onAnnouncementOpen();
      }} size={'xs'} leftIcon={<FiEdit size={'14px'}/>} colorScheme="blue" >Edit</Button>
    },
  ]);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass: "no-border",
    resizable: true
  }));

  const SaveAnnouncementData = async (e) => {
    e.preventDefault();
    let body = {
      ...AnnouncementData,
      startDate: new Date(AnnouncementData.startDate),
      endDate: new Date(AnnouncementData.endDate),
    }
    console.log("Save Announcement Body",body);

    const response = await PostAddAnnouncementData(body);

    if(response){
      RefetchAnnouncementList();
      onAnnouncementClose();
      setAnnouncementData({
        id: 0,
        contentDetail: "",
        startDate: "",
        endDate: "",
        isActive: true
      })
    }
  }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Announcements</h1>
        <div>
          <Button
            size={"sm"}
            onClick={() => {
              onAnnouncementOpen();
            }}
            leftIcon={<FiPlus size={"16px"} />}
            colorScheme="orange"
          >
            Add Announcement
          </Button>
        </div>
      </div>

      {IsAnnouncementListLoading ? <Center>
  <Spinner thickness='4px' color={'orange.500 '}  size='lg'  />
</Center> : <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={FetchedAnnouncementList} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>}


      <Modal size={'xl'} isOpen={isAnnouncementOpen} onClose={()=>{
                    onAnnouncementClose();
                    setAnnouncementData({
                      id: 0,
                      contentDetail: "",
                      startDate: "",
                      endDate: "",
                      isActive: true
                    })
            }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Announcement</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveAnnouncementData}>
          <ModalBody className='space-y-4'>
            <div className='grid grid-cols-2'>
              <div className='space-y-2'>
                <label>Start Date</label>
                <div>
                <DatePicker
                  required
                  className="bg-white border w-full border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block  p-2.5   outline-none"
                  selected={AnnouncementData?.startDate || ""}
                  onChange={(date) => {
                    setAnnouncementData({
                      ...AnnouncementData,
                      startDate: date,
                    });
                  }}
                  minDate={new Date()}
                  placeholderText="Start Date"
                  dateFormat={"dd-MM-yyyy"}
                />
                </div>
              </div>
              <div className='space-y-2'>
                <label>End Date</label>
                <div>
                <DatePicker
                  required
                  className="bg-white border w-full border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block  p-2.5   outline-none"
                  selected={AnnouncementData?.endDate || ""}
                  onChange={(date) => {
                    setAnnouncementData({
                      ...AnnouncementData,
                      endDate: date,
                    });
                  }}
                  minDate={new Date()}
                  placeholderText="End Date"
                  dateFormat={"dd-MM-yyyy"}
                />
                </div>
              </div>
            </div>
            
            <div className='space-y-2'>
              <label>Content</label>
              <Textarea value={AnnouncementData?.contentDetail} onChange={({target})=>{
                 setAnnouncementData({
                  ...AnnouncementData,
                  contentDetail: target.value
                 })
              }} required rows={6} borderColor={'gray.400'} placeholder='Content'/>
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button type="submit" isLoading={IsAddAnnouncementLoading} loadingText={'Saving...'} colorScheme='orange' mr={3} >
              Save
            </Button>
            <Button colorScheme="orange" variant='outline' onClick={()=>{
                    onAnnouncementClose();
                    setAnnouncementData({
                      id: 0,
                      contentDetail: "",
                      startDate: "",
                      endDate: "",
                      isActive: true
                    })
            }}>Cancel</Button>
          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default Announcements