import React from "react";
import { IoLogoWhatsapp } from "react-icons/io";
const WhatsAppContact = () => {
  return (
    <a
      href="https://web.whatsapp.com/send?phone=9999763637&text=Hello%2C%20I%20visited%20your%20site.%20Can%20you%20help%20me%20learn%20more%20about%20Globus%20Certifications."
      target="_blank"
      rel="noopener noreferrer"
    >
      <IoLogoWhatsapp size={'3rem'} className="text-green-500 drop-shadow-lg" />
    </a>
  );
};

export default WhatsAppContact;
