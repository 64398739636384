import React from 'react'
import PageHeader from '../UiComponents/PageHeader'
import CertAction from '../UiComponents/CertAction'

const SampleCerts = () => {
  return (
    <div>
        <PageHeader PageTitle={'Sample Certificates'} PageDescription={'Listed below are Sample Certificates.'}/> 
        <div className="max-w-screen-2xl py-16 px-5 mx-auto text-center">
         <h1 className="text-3xl text-amber-500 font-bold mb-10">Sample Certificates</h1>
         <div className='grid grid-cols-1  lg:grid-cols-2 gap-5'>
           <div>
              <embed
                src={"/documents/Sample1.pdf"}
                type="application/pdf"
                className=" w-full !select-none"
                height={800}
                width={800}
              />
           </div>
           <div>
              <embed
                src={"/documents/Sample1.pdf"}
                type="application/pdf"
                className=" w-full !select-none"
                height={800}
                width={500}
              />
           </div>
           <div>
              <embed
                src={"/documents/Sample1.pdf"}
                type="application/pdf"
                className=" w-full !select-none"
                height={800}
                width={500}
              />
           </div>
         </div>
        </div>

        <CertAction/>
    </div>
  )
}

export default SampleCerts