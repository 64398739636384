import React from 'react'
import PageHeader from '../UiComponents/PageHeader'
import CertAction from '../UiComponents/CertAction'
import Milestone from './Milestone'

const Trainings = () => {
  return (
    <div>
        <PageHeader PageTitle={'Trainings'} PageDescription={'Get yourself trained in ISO, Lean, and Six Sigma'} />
        <section className="text-gray-600 body-font">
  <div className="max-w-screen-xl px-5 py-24 mx-auto">
    <div className="flex flex-col text-center w-full mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium  mb-4 text-amber-500">Our Trainings</h1>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Globus Certifications is committed to providing high-quality services to its customers. We offer training services for ISO standards, Lean and Six Sigma methodologies to clients all across the globe. We provide instructor-led classroom/online training in the following areas:</p>
    </div>
    <div className="flex flex-wrap">
      <div className="lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
        <h2 className="text-lg sm:text-xl text-amber-500 font-medium  mb-2">Overview of ISO Standards</h2>
        <p className="leading-relaxed text-base mb-4">This ISO training is designed to help you understand the key features of ISO standards including basic concepts, terminology and overview of standard requirements.</p>
      </div>
      <div className="lg:w-1/2 md:w-full px-8 py-6 border-l-2 border-gray-200 border-opacity-60">
        <h2 className="text-lg sm:text-xl text-amber-500 font-medium  mb-2">ISO Internal Auditor Training Course</h2>
        <p className="leading-relaxed text-base mb-4">This is an important ISO training course since the organisation needs an internal auditor to check for compliance. Internal audits are crucial since they help in finding areas of non-conformance before external audits are carried out.</p>
      </div>
    </div>
  </div>
</section>

<section className="text-gray-600 body-font">
  <div className="max-w-screen-xl px-5 py-24 mx-auto">
    <div className="flex flex-col">
      <div className="h-1 bg-gray-200 rounded overflow-hidden">
        <div className="w-24 h-full bg-yellow-500"></div>
      </div>
      <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
        <h1 className="sm:w-2/5 text-amber-500 font-medium title-font text-2xl mb-2 sm:mb-0">Types of Six Sigma Training Courses</h1>
      </div>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        <h2 className="text-xl font-medium title-font text-amber-500 mt-5">Yellow Belt Training</h2>
        <p className="text-base leading-relaxed mt-2">This is considered to be the beginner’s course since a professional learns the foundational elements of the Six Sigma Methodology. After successfully completing this training, the professional can lead limited improvement projects.</p>
      </div>
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        <h2 className="text-xl font-medium title-font text-amber-500 mt-5">Green Belt Training</h2>
        <p className="text-base leading-relaxed mt-2">This training course deals with the advanced elements of Six Sigma where a professional understands how to properly implement, perform, interpret and apply Six Sigma principles. Professionals can lead complex improvement projects under a Certified Black Belt. After completing this course.</p>
      </div>
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        <h2 className="text-xl font-medium title-font text-amber-500 mt-5"> Black Belt Training</h2>
        <p className="text-base leading-relaxed mt-2">This is the expert level course that gives professionals that equips them with the knowledge to lead complex improvement projects.</p>
      </div>
    </div>
  </div>
</section>

<section className="text-gray-600 body-font">
  <div className="max-w-screen-xl px-5 py-24 mx-auto">
    <div className="flex flex-col">
      <div className="h-1 bg-gray-200 rounded overflow-hidden">
        <div className="w-24 h-full bg-yellow-500"></div>
      </div>
      <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
        <h1 className="sm:w-2/5 text-amber-500 font-medium title-font text-2xl mb-2 sm:mb-0">Lean Training Courses</h1>
      </div>
    </div>
    <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        <h2 className="text-xl font-medium title-font text-amber-500 mt-5">Learn Introductory Course</h2>
        <p className="text-base leading-relaxed mt-2">This training course is the introductory course to the Lean philosophy. Professionals understand the Lean principles and also the philosophy behind them.</p>
      </div>
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        <h2 className="text-xl font-medium title-font text-amber-500 mt-5">Tools Selection Criteria</h2>
        <p className="text-base leading-relaxed mt-2">If you want to implement lean principles, you need to know which tools will get you the desired results. This training course is designed to help you understand the selection criteria for tools.</p>
      </div>
      <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
        <h2 className="text-xl font-medium title-font text-amber-500 mt-5"> Execution of Tools</h2>
        <p className="text-base leading-relaxed mt-2">You will understand how to make the most of the tools for Lean methodology by attending this training. It is an advanced course that helps you understand Lean principles and how to implement them in an organisation.</p>
      </div>
    </div>
  </div>
</section>

    <div className="flex flex-col text-center w-full mb-20">
      <h1 className="sm:text-3xl text-2xl font-medium  mb-4 text-amber-500">Why Globus Certifications</h1>
      <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Global Certifications offers a wide range of training courses in India for organisations, big and small. From ISO to Lean and Six Sigma, we help organisations get the desired results fast. Get in touch with us at <a className="font-bold text-gray-800" href="mailto:info@gcert.co">info@gcert.co</a> for details.</p>
    </div>

    <Milestone/>
    <CertAction/>
    </div>
  )
}

export default Trainings