import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Button, useDisclosure, Input, Textarea, Select, Center, Spinner } from "@chakra-ui/react";
import React, { useState, useMemo, useCallback } from "react";
import { FiPlus } from "react-icons/fi";
import DatePicker from "react-datepicker";
import moment from "moment";
import usePost from "../Utils/usePost";
import { AgGridReact } from "ag-grid-react";
import useFetch from "../Utils/useFetch";
import { FiEdit } from "react-icons/fi";


const AddCertificate = () => {

  const { IsPostLoading: IsAddCertificateLoading, PostData: PostAddCertificateData } = usePost('/api/Certificate/SaveCertificate');
  const { IsFetchLoading: IsCertificateListLoading, FetchedData: FetchedCertificateList, Refetch: RefetchCertificateList } = useFetch('/api/Certificate/GetCertificateList');

  const {
    isOpen: isAddCertificateOpen,
    onOpen: onAddCertificateOpen,
    onClose: onAddCertificateClose
  } = useDisclosure();
  const [CertificateDetails, setCertificateDetails] = useState({
    status: "",
    certificate: "",
    issueDate: "",
    expiryDate: "",
    description: "",
    standard: "",
    organization: "",
    address: ""
  });

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [ columnDefs ] = useState([
    {
      headerName: "Company Name",
      field: "organization",
    },
    {
      headerName: "Certificate Number",
      field: "certificate",
    },
    {
      headerName: "Standard",
      field: "standard",
    },
    {
      headerName: "Address",
      field: "address",
    },
    {
      headerName: "Scope",
      field: "description",
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: ({data})=> <span
      className={`${
        data?.status === "Active"
          ? "bg-green-500"
          : data?.status === "Suspend"
          ? "bg-red-500"
          : data?.status === "Hold" ? "bg-yellow-500"
          :  data?.status === "Withdrawl" ? "bg-blue-500" : "bg-orange-500"
      } text-white rounded-md px-2 py-0.5 font-medium`}
    >
      {data?.status}
    </span>
    },
    {
      headerName: "Issue Date",
      field: "issueDate",
      cellRenderer:({data})=> moment(data.issueDate).format('DD-MMM-YYYY')
    },
    {
      headerName: "Expiry Date",
      field: "expiryDate",
      cellRenderer:({data})=> moment(data.expiryDate).format('DD-MMM-YYYY')
    },
    {
      headerName: "Action",
      pinned: 'right',
      cellRenderer: ({data}) => <Button onClick={()=>{
        setCertificateDetails({
          ...data,
          issueDate: new Date(data.issueDate),
          expiryDate: new Date(data.expiryDate)
        });
        onAddCertificateOpen();
      }} size={'xs'} leftIcon={<FiEdit size={'14px'}/>} colorScheme="blue" >Edit</Button>
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellClass: "no-border",
    enableCellTextSelection: true,
    resizable: true
  }));


  const HandleCertificateForm = async () => {
    // e.preventDefault();

    let body = {
       ...CertificateDetails,
       issueDate: moment(CertificateDetails?.issueDate).format('YYYY-MM-DD'),
       expiryDate: moment(CertificateDetails?.expiryDate).format('YYYY-MM-DD'),
    }
    const response = await PostAddCertificateData(body);
    if (response) {
      RefetchCertificateList();
      setCertificateDetails({
        status: "",
        certificate: "",
        issueDate: "",
        expiryDate: "",
        description: "",
        standard: "",
        organization: "",
        address: "",
      });
      onAddCertificateClose();
    }
  }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Certificates</h1>
        <div>
          <Button
            size={"sm"}
            onClick={() => {
              onAddCertificateOpen();
            }}
            leftIcon={<FiPlus size={"16px"} />}
            colorScheme="orange"
          >
            Add Certificate
          </Button>
        </div>
      </div>

      {IsCertificateListLoading ? <Center>
        <Spinner thickness='4px' color={'orange.500 '}  size='lg'  />
      </Center> : <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={FetchedCertificateList} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
           paginationPageSize={20}
           paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>}

      <Drawer
        isOpen={isAddCertificateOpen}
        placement="right"
        size={"md"}
        onClose={() => {
          setCertificateDetails({
            status: "",
            certificate: "",
            issueDate: "",
            expiryDate: "",
            description: "",
            standard: "",
            organization: "",
            address: "",
          });
          onAddCertificateClose();
        }}
      >
        <DrawerOverlay />
        <DrawerContent roundedLeft={"3xl"}>
          <DrawerCloseButton mt={1} />
          <DrawerHeader
            textColor={"gray.800"}
            fontSize="lg"
            backgroundColor={"orange.300"}
            roundedTopLeft={"3xl"}
            roundedBottomLeft={"xl"}
            fontWeight="bold"
          >
            Add Certificate
          </DrawerHeader>
          {/* <form onSubmit={HandleCertificateForm}> */}
          <DrawerBody className="space-y-4">
            <div className="space-y-2">
              <label>Name of the Company</label>
              <Input
                isRequired
                value={CertificateDetails?.organization || ""}
                onChange={({ target }) => {
                  setCertificateDetails({
                    ...CertificateDetails,
                    organization: target.value,
                  });
                }}
                borderColor={"gray.400"}
                placeholder="Name of the company"
              />
            </div>
            <div className="space-y-2">
              <label>Certificate Number</label>
              <Input
                isRequired
                value={CertificateDetails?.certificate || ""}
                onChange={({ target }) => {
                  setCertificateDetails({
                    ...CertificateDetails,
                    certificate: target.value,
                  });
                }}
                borderColor={"gray.400"}
                placeholder="Certificate Number"
              />
            </div>
            <div className="space-y-2">
              <label>Certificate Standard</label>
              <Input
                isRequired
                value={CertificateDetails?.standard || ""}
                onChange={({ target }) => {
                  setCertificateDetails({
                    ...CertificateDetails,
                    standard: target.value,
                  });
                }}
                borderColor={"gray.400"}
                placeholder="Certificate Standard"
              />
            </div>
            <div className="space-y-2">
              <label>Company Address</label>
              <Textarea
                isRequired
                rows={4}
                value={CertificateDetails?.address || ""}
                onChange={({ target }) => {
                  setCertificateDetails({
                    ...CertificateDetails,
                    address: target.value,
                  });
                }}
                borderColor={"gray.400"}
                placeholder="Company Address"
              />
            </div>
            <div className="space-y-2">
              <label>Scope of the Certification</label>
              <Textarea
                isRequired
                rows={4}
                value={CertificateDetails?.description || ""}
                onChange={({ target }) => {
                  setCertificateDetails({
                    ...CertificateDetails,
                    description: target.value,
                  });
                }}
                borderColor={"gray.400"}
                placeholder="Scope of the Certification"
              />
            </div>
            <div className="space-y-2">
              <label>Certificate Status</label>
              <Select isRequired value={CertificateDetails?.status || ""}
                onChange={({ target }) => {
                  setCertificateDetails({
                    ...CertificateDetails,
                    status: target.value,
                  });
                }} borderColor={"gray.400"} placeholder="Select Status">
                <option value={"Active"}>Active</option>
                <option value={"Suspend"}>Suspend</option>
                <option value={"Withdrawl"}>Withdrawl</option>
                <option value={"Hold"}>Hold</option>
              </Select>
            </div>
            <div className="space-y-2">
              <label>Issue Date</label>
              <div>
                <DatePicker
                  required
                  className="bg-white border !w-[29rem] border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block  p-2.5   outline-none"
                  selected={CertificateDetails?.issueDate || ""}
                  onChange={(date) => {
                    setCertificateDetails({
                      ...CertificateDetails,
                      issueDate: date,
                    });
                  }}
                  placeholderText="Issue Date"
                  dateFormat={"dd-MM-yyyy"}
                />
              </div>
            </div>
            <div className="space-y-2">
              <label>Expiry Date</label>
              <div>
                <DatePicker
                  required
                  className="bg-white border !w-[29rem]  border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block  p-2.5   outline-none"
                  selected={CertificateDetails?.expiryDate || ""}
                  onChange={(date) => {
                    setCertificateDetails({
                      ...CertificateDetails,
                      expiryDate: date,
                    });
                  }}
                  placeholderText="Expiry Date"
                  dateFormat={"dd-MM-yyyy"}
                />
              </div>
            </div>
          </DrawerBody>
          <DrawerFooter>
            <Button
              colorScheme="orange"
              variant="outline"
              mr={3}
              onClick={() => {
                setCertificateDetails({
                  status: "",
                  certificate: "",
                  issueDate: "",
                  expiryDate: "",
                  description: "",
                  standard: "",
                  organization: "",
                  address: "",
                });
                onAddCertificateClose();
              }}
            >
              Cancel
            </Button>
            <Button isLoading={IsAddCertificateLoading} loadingText="Saving..." onClick={()=>{
              HandleCertificateForm();
            }} colorScheme="orange">Save</Button>
          </DrawerFooter>
          {/* </form> */}
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default AddCertificate;
