import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { useState } from "react";

const usePost = (endpoint) => {
  const toast = useToast();
  const id = "toast";
  const [IsPostLoading, setIsPostLoading] = useState(false);
  const [PostError, setPostError] = useState(null);

  const PostData = async (data) => {

    setIsPostLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_GCERT_UI}${endpoint}`,
        data
      );
      console.log("Post Hook Response", response);

      if(endpoint !== "/api/Account/UserLogin"){

      if ( typeof response?.data !== "object") {
        if (!toast.isActive(id)) {
          toast({
            id,
            title: response.data,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }
      }else{
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "Done",
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }
      }
    }
      return response.data;
    } catch (err) {
      console.error("Post Hook PostError", err);
      setPostError(err?.message);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: err?.message,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        })
      }
    } finally {
      setIsPostLoading(false);
    }
  };

  return { IsPostLoading, PostError, PostData };
};

export default usePost;
