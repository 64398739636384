import React, { useState } from 'react'
import PageHeader from '../UiComponents/PageHeader'
import { FaPhone, FaLocationDot, FaEnvelope } from "react-icons/fa6";
import { Button, FormControl, FormLabel, Input, Textarea } from '@chakra-ui/react'
import { LuBadgeCheck } from 'react-icons/lu';
import usePost from  '../Utils/usePost';
import { ContactFormSchema } from '../Utils/FormValidation';
const ContactUs = () => {

  const { IsPostLoading, PostData } = usePost('/api/ContactUs/SendEmailBySendGrid');
  const [FormErrors, setFormErrors] = useState({});
  const [ContactDetails, setContactDetails] = useState({
    name: "",
    email: "",
    contactNo: "",
    message: ""
  });

  const [ShowMsg, setShowMsg] = useState(false);

  const SaveContactForm = async (e) => {
    e.preventDefault();
    setFormErrors({});

    ContactFormSchema.validate(ContactDetails,{abortEarly:false})
    .then(async() => {

    const FormBody = new FormData();

    FormBody.append('EmailSubject','New Form Submission')
    FormBody.append('EmailBody',`<div>
    <p><b>Name: </b> ${ContactDetails.name}<br/>
     <b>Email Address: </b> ${ContactDetails.email}<br/>
     <b>Contact No.: </b> ${ContactDetails.contactNo}<br/>
     <b>Message: </b> ${ContactDetails.message}</p>
    </div>`)

     console.log("Email Body",FormBody);
     const response = await PostData(FormBody);
     console.log("response",response);

     if(response){
       setContactDetails({
         name: "",
         email: "",
         contactNo: "",
         message: ""
       });
       setShowMsg(true);
       setTimeout(() => {
         setShowMsg(false);
       }, 3000);
       
     }

   }).catch((validationErrors) => {
     const newErrors = {};
     validationErrors.inner.forEach((error) => {
       newErrors[error.path] = error.message;
     });
     setFormErrors(newErrors);
   });
     

 }


  return (
    <div>
        <PageHeader PageTitle={'Contact Us'} PageDescription={'We have rich experience of helping organizations achieve results through ISO certification.'} />
        <div className="max-w-screen-xl px-5 py-24 mx-auto">
            <div className='flex flex-wrap-reverse md:flex-nowrap justify-center gap-6'>
            <div className="max-w-sm text-center ">
          <h2 className="text-gray-800 font-semibold text-3xl mb-2">Contact Info</h2>
          <p className="text-gray-500">We give unbiased and reliable certification services to our Clients!</p>
        <div className="space-y-10 mt-12 ">  
           <div>
            <FaLocationDot className="text-4xl mx-auto mb-4 text-amber-500"  />
            <h6 className="text-gray-500">Address</h6>
            <p  className="text-gray-800 font-semibold text-lg">
            15, 4th/F, Partap Nagar, Mayur Vihar, Ph-1, New Delhi – 110091
            </p>
          </div> 
          <div>
            <FaPhone className="text-4xl mx-auto mb-4 text-amber-500"  />
            <h6 className="text-gray-500">Phone Number</h6>
            <p className="text-gray-800 font-semibold text-xl">+91-9999763637, +91-9918728945</p>
          </div>
          <div>
            <FaEnvelope className="text-4xl mx-auto mb-4 text-amber-500"  />
            <h6 className="text-gray-500">Email</h6>
            <p  className="text-gray-800 font-semibold text-xl">info@gcert.co</p>
          </div>
          </div> 
        </div>
        <div>
          <div className='space-y-2'>
            <h2 className="text-gray-800 font-semibold text-3xl">Leave Your Message</h2>
            <p className="text-gray-500">Feel free to contact with us by using the form below</p>
          </div>
          {ShowMsg ? <div className='text-center mt-28 bg-white bg-opacity-30 border-2 py-8 border-dashed rounded-3xl'>
          <div className='space-y-2 my-auto'>
            <LuBadgeCheck size={'6rem'} className='mx-auto text-green-500 animate-bounce'/>
            <h6 className='text-3xl font-bold text-gray-800'>Thank You!!</h6>
            <p className='text-gray-500'>We will get back to you as soon as possible.</p>
          </div>
        </div>:
          <form onSubmit={SaveContactForm} className="mt-12 space-y-10">
          <FormControl variant={'floatingFooter'}>
            <Input isInvalid={FormErrors?.name} value={ContactDetails?.name} onChange={({target})=>{
              setContactDetails(
                {
                  ...ContactDetails,
                  name: target.value
                }
              )
            }} variant={'flushed'} borderColor={'gray.400'} placeholder='' />
            <FormLabel>Name</FormLabel>
            {FormErrors?.name && <span className='text-red-600'>{FormErrors?.name}</span>}
          </FormControl>
          <FormControl variant={'floatingFooter'}>
            <Input isInvalid={FormErrors?.email} type='email' value={ContactDetails?.email} onChange={({target})=>{
              setContactDetails(
                {
                  ...ContactDetails,
                  email: target.value
                }
              )
            }} variant={'flushed'} borderColor={'gray.400'} placeholder='' />
            <FormLabel>Email</FormLabel>
            {FormErrors?.email && <span className='text-red-600'>{FormErrors?.email}</span>}
          </FormControl>
          <FormControl variant={'floatingFooter'}>
            <Input isInvalid={FormErrors?.contactNo} type='number' value={ContactDetails?.contactNo} onChange={({target})=>{
              setContactDetails(
                {
                  ...ContactDetails,
                  contactNo: target.value
                }
              )
            }} variant={'flushed'} borderColor={'gray.400'} placeholder='' />
            <FormLabel>Contact No.</FormLabel>
            {FormErrors?.contactNo && <span className='text-red-600'>{FormErrors?.contactNo}</span>}
          </FormControl>
          <FormControl variant={'floatingFooter'}>
            <Textarea isInvalid={FormErrors?.message} value={ContactDetails?.message} onChange={({target})=>{
              setContactDetails(
                {
                  ...ContactDetails,
                  message: target.value
                }
              )
            }} rows={2} variant={'flushed'} borderColor={'gray.400'} placeholder='' />
            <FormLabel>Message</FormLabel>
            {FormErrors?.message && <span className='text-red-600'>{FormErrors?.message}</span>}
          </FormControl>
           <Button isLoading={IsPostLoading} loadingText="Submitting..." type='submit' colorScheme='orange'>Submit</Button>
          </form>}
    
        </div>
        </div>
      </div>
    </div>
  )

}

export default ContactUs